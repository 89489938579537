export default class User {

  public constructor(private profile: any) {
    this.profile.email = profile.email || "";
    this.profile.firstName = profile.firstName || "Leitor(a)";
    this.profile.lastName = profile.lastName || "";
    this.profile.fullName = profile.fullName || "";
    this.profile.attributes = profile.attributes || {};
  }

  /**
   * Retrieve an attribute from profile
   */
  public getAttributeValue(attribute: string): string {
    const values = this.profile.attributes[attribute] || "";

    return (typeof values === "string") ? values : values[0];
  }

  public getId(): string {
    return this.profile.sub;
  }

  public getEmail(): string {
    return this.profile.email;
  }

  public getFirstName(): string {
    return this.profile.firstName;
  }

  public getLastName(): string {
    return this.profile.lastName;
  }

  public getFullname(): string {
    return this.profile.fullName;
  }

  /**
   * Will try from attribute and from Facebook default avatar.
   */
  public getAvatar(): string {
    const avatar = this.getAttributeValue("avatar");
    if (avatar) {
      return avatar;
    }

    const facebookId = this.getAttributeValue("facebook_id");
    if (facebookId) {
      return `https://graph.facebook.com/${facebookId}/picture`;
    }

    return "";
  }
}
