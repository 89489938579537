import { CheckRuleResponse } from "concerns/check-rule-response";
import JSONWrapper from "helpers/json-wrapper";
import Logger from "helpers/logger";
import URI = require("urijs");

export default class SignInWallApi {

  public constructor(readonly baseUrl: string) { }

  /**
   * Based on user current data and state, consults signinwall to get the rule action to be executed
   *
   * @return {Promise<CheckRuleResponse>|null}
   */

  public async checkRule(data: any): Promise<CheckRuleResponse> {

    const url = this.buildUrl("signinwall/check");
    const request = await fetch(url, {
      body: JSONWrapper.stringify(data),
      headers: { "Content-Type": "application/json" },
      method: "POST",
    });

    return this.processResponse<CheckRuleResponse>(request.json());
  }

  /**
   * Process a received response from API and returns the expected parsed result or null;
   *
   * @param  {Promise<any>} response
   * @return {Promise<SubscribeAPIResult<T>>}
   */
  private processResponse<T>(response: Promise<any>): Promise<T> {
    return response.then((payload: any) => {
      if (!payload.success) {
        return null;
      }
      return payload as T;
    }).catch((error): null => {
      Logger.debug("processResponse error:");
      Logger.debug(error);
      return null;
    });
  }

  /**
   * Build a URL from base with given path and params
   *
   * @param  {string} path
   * @param  {any}    params
   * @return {string}
   */
  private buildUrl(path: string, params?: any): string {
    if (typeof params !== "object") {
      params = {};
    }
    return URI(this.baseUrl + "/" + path).addQuery(params).normalize().toString();
  }
}
