import { SubscribeApiPayment } from "concerns/subscribe-api-payment";
import { GPayEvent } from "events/payment-event";

export default class GoogleToSubscribeApiPaymentMap {
  public map(source: GPayEvent): Partial<SubscribeApiPayment> {
    const token = JSON.parse(source.paymentMethodData.tokenizationData.token);

    return {
      plan_title: source.plan_title,
      variation_id: source.variation_id,
      installments: 1,
      type: "android-pay",
      wl_token: token.signature,
      wl_walletkey: token.signedMessage,
      user_identity: source.user_identity,
      user_phone: source.user_phone
    };
  }
}
