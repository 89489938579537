import { CheckRuleResponse } from "concerns/check-rule-response";
import { LoginStatus } from "concerns/login-status";
import LoginStatusStatus from "concerns/login-status-status";
import SubscriberStatus from "concerns/subscriber-status";
import { TrackerData } from "concerns/tracker-data";
import CheckRuleResponseAction from "concerns/check-rule-response-action";
import Main from "facades/main";
import Frontend from "helpers/frontend";
import Logger from "helpers/logger";
import SignInWallApi from "../integrations/sign-in-wall-api";
import WallFactory from "walls/wall-factory";
import Env from "environment/env";
import Analytics from "helpers/analytics";

export default class SignInWall {

  private initialized: boolean = false;
  private wrapperId: string;
  private loginStatus: LoginStatus;
  private analytics: Analytics;

  constructor(
    private api: SignInWallApi,
    private trackingData: any
  ) {}

  public init(wrapperId: string) {
    this.wrapperId = wrapperId;

    Main.getLoginStatus((response: any) => {
      this.loginStatus = response as LoginStatus;
      this.loadTracking(this.loginStatus, true);
    });
  }

  /**
   * Initialize the tracking without load SignInWall
   */
  public tracking() {
    if (this.invalidAppId) {
      Logger.error("Você deve informar o valor de 'appId' para o tracking.");
      return;
    }

    Main.getLoginStatus((response: any) => {
      this.loginStatus = response as LoginStatus;
      this.loadTracking(this.loginStatus, false);
    });
  }

  private invalidAppId(): boolean {
    return !this.trackingData || !this.trackingData.appId;
  }

  /**
   * Will merge tracking data from SJCC_Login.init with user data.
   * If there's a jcTracker value we will merge overwriting.
   */
  private async loadTracking(response: LoginStatus, loadSigninWall: boolean) {
    if (this.initialized) {
      Logger.error("Tracking já foi inicializado.");
      return;
    }

    const oldTracker = Object.assign({}, ((window as any).jcTracker || {}), this.trackingData);
    const email = response.userinfo ? response.userinfo.getEmail() : "";
    const newTracker = {
      appId: this.trackingData.appId,
      baseUrl: Env.tracking.baseUrl(),
      email,
      onSuccess: this.onTrackerSuccess(oldTracker, loadSigninWall),
    };

    // Create tracker
    (window as any).jcTracker = Object.assign({}, oldTracker, this.trackingData, newTracker);
    this.applyTrackingScripts();
    this.initialized = true;

    // Set Analytics
    this.analytics = new Analytics((window as any).jcTracker.metadata);
  }

  private onTrackerSuccess(oldTracker: any, loadSigninWall: boolean) {
    return (callback: any) => {
      if (this.isFunction(oldTracker.onSuccess)) {
        oldTracker.onSuccess.call((window as Window), callback);
      }

      if (loadSigninWall) {
        this.start(callback);
      }
    };
  }

  private isFunction(param: any): boolean {
    return (param && typeof param === "function");
  }

  private applyTrackingScripts() {
    Frontend.script(Env.tracking.jsUrl());
  }

  /**
   * Load SignInWall after tracking returns
   */
  private async start(trackingResponse: any) {
    const element = document.querySelector(this.wrapperId) as HTMLElement;

    if (!element) {
      Logger.error("O elemento da notícia para o SignIn Wall é inválido.");
      return;
    }

    const trackerData = this.trackigResponseDeviceToTrackerData(trackingResponse.device);
    const response: CheckRuleResponse = await this.checkRule(trackerData);

    if (response.action === "none") {
      return;
    }

    // SignInWall Analytics
    this.analytics.send({
      event: 'signinwall',
      signinwall: response.action,
      signinwall_loginstatus: this.loginStatus.status
    });

    const wallFactory = new WallFactory(element, this.loginStatus, this.trackingData.metadata);
    const wallLoader = wallFactory.fromCheckRuleResponse(response);
    if (wallLoader !== null) {
      wallLoader.load();
    }

    // Add class to body
    document.body.classList.add('has-signinwall-iframe', 'has-signinwall-' + response.action);
  }

  private trackigResponseDeviceToTrackerData(device: any): TrackerData {
    return {
      app_id: this.trackingData.appId,
      device: {
        cache_uuid: device.cacheUuid || "",
        cookie_uuid: device.cookieUuid || "",
        fingerprint: device.fingerprint || "",
        local_storage_uuid: device.localStorageUuid || "",
      },
    } as TrackerData;
  }

  private async checkRule(tracking: TrackerData): Promise<CheckRuleResponse | null> {
    const data = Object.assign(this.dataToCheckRule(), { tracking });
    Logger.debug(data);
    return this.api.checkRule(data);
  }

  private dataToCheckRule(): any {
    const state = this.getUserState();

    return {
      referrer: document.referrer,
      tags: this.jcOnlineRuleTags(),
      url: (window as Window).location.href,
      user_state: state,
      user_email: this.loginStatus?.userinfo?.getEmail() ?? '',
    };
  }

  private getUserState(): string {
    if (this.loginStatus.status === LoginStatusStatus.CONNECTED) {
      return SubscriberStatus.parseUserInfo(this.loginStatus.userinfo.userdata);
    }

    return LoginStatusStatus.NOT_CONNECTED;
  }

  private jcOnlineRuleTags(): string[] {
    const metatags = document.querySelectorAll<HTMLMetaElement>('meta[name="keywords"], meta[property="article:tag"]');
    const keywords = [];
    for (let i = metatags.length - 1; i >= 0; i--) {
      const tags = metatags[i].content.split(",");
      for (let j = tags.length - 1; j >= 0; j--) {
        keywords.push(tags[j].trim());
      }
    }

    return keywords;
  }
}
