import Frontend from "helpers/frontend";
import Component from "components/component";

export default abstract class AppScreen implements Component {
  public constructor(protected wrapperElement: Element) { }

  public async load(): Promise<void> {
    await this.preBuild();
    const screen = await this.build();
    await this.applyScreen(screen);
    await this.loadCss();
    await this.postApplyScreen();
  }

  protected async preBuild(): Promise<void> {
    if (! this.wrapperElement) {
      return;
    }
  }

  public async build(): Promise<HTMLElement> { throw new Error("not implemented"); }

  protected async applyScreen(screen: HTMLElement): Promise<void> {
    this.wrapperElement.innerHTML = "";
    this.wrapperElement.appendChild(screen);
  }

  protected async postApplyScreen(): Promise<void> { }

  protected async loadCss(): Promise<void> {
    Frontend.stylesheet("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
    Frontend.makeStyle(this.style());
    Frontend.makeMobileStyle(this.styleMobile());
  }

  public abstract style(): string;

  public styleMobile(): string {
    return '';
  }
}
