import Frontend from 'helpers/frontend';
import Logger from 'helpers/logger';
import PaymentButton from 'payment/interface/payment-button';
import Observer from 'observers/observer';
import PlanSelectEvent from 'events/plan-select-event';
import { GPayEvent } from 'events/payment-event';
import LoadedEWalletEvent from "events/loaded-ewallet-event";
import { IsReadyToPayRequestBuilder } from './request-builders/is-ready-to-pay-request-builder';
import { PaymentOptionsProvider } from './providers/payment-options-provider';
import { PaymentDataRequestBuilder } from './request-builders/payment-data-request-builder';

export default class GooglePay extends PaymentButton implements Observer<PlanSelectEvent>  {
  private client: google.payments.api.PaymentsClient;
  private transaction: google.payments.api.TransactionInfo;
  private plan_title: string;
  private variation_id: number;

  private alreadyBuilt: boolean = false;

  public constructor(protected isActive: boolean) {
    super();

    if (! this.isActive) {
      return;
    }

    Logger.debug('[GooglePay] Loading scripts.');
    Frontend.script('https://pay.google.com/gp/p/js/pay.js', {'async': true, 'onload': () => { this.onGoogleLoaded() }});
  }

  public onGoogleLoaded(): void {
    const paymentOptionsProvider = new PaymentOptionsProvider();
    this.client = new google.payments.api.PaymentsClient(paymentOptionsProvider.getPaymentOptions());

    if (this.alreadyBuilt) {
      return;
    }

    this.createButton();
  }

  public async allowed(): Promise<boolean> {
    if (! this.isActive) {
      return false;
    }

    const requestBuilder = new IsReadyToPayRequestBuilder();
    const response = await this.client.isReadyToPay(requestBuilder.getRequest());

    return response.result;
  }

  public async createButton(): Promise<void> {
    if (! this.client) {
      return;
    }

    if (! await this.allowed()) {
      return;
    }

    const buttonsWrapper = document.getElementById('ewallets-buttons');

    if (! buttonsWrapper) {
      return;
    }

    this.alreadyBuilt = true;

    buttonsWrapper.appendChild(await this.build());
    Frontend.makeStyle(this.style());

    this.notify({wallet : 'GooglePay'} as LoadedEWalletEvent);
  }

  public async build(): Promise<HTMLElement> {
    return Frontend.div({
      class: 'google-pay-button'
    },
    [
      this.client.createButton({
        onClick: async () => { await this.onClick(); }
      })
    ]);
  }

  private async onClick() {
    if (! this.validatePersonalData()) {
      return;
    }

    const requestBuilder = new PaymentDataRequestBuilder();

    let request = requestBuilder.getRequest(this.transaction);
    this.client.prefetchPaymentData(request);

    const payment = await this.client.loadPaymentData(request);
    const paymentData = {
      user_phone: this.formValue('telephone'),
      user_identity: this.formValue('cpf'),
      plan_title: this.plan_title,
      variation_id: this.variation_id,
      installments: 1
    };

    const notification: GPayEvent = Object.assign(payment, paymentData);

    this.notify(notification);
  }

  private formValue(name: string): string {
    const element = document.getElementsByName(name);
    if (element.length === 0) {
      return '';
    }

    return (element[0] as HTMLInputElement).value;
  }

  public style(): string {
    return `
      .google-pay-button .gpay-button {
        max-width: 250px !important;
        width: 100% !important;
      }
     .google-pay-button .gpay-card-info-container {
        min-width: 250px;
        min-height: 40px;
      }
    `;
  }

  public async handle(event: PlanSelectEvent): Promise<void> {
    return this.onPlanSelect(event as PlanSelectEvent);
  }

  private async onPlanSelect(event: PlanSelectEvent): Promise<void> {
    this.transaction = {
      displayItems: [
        {
          label: event.campaign.name,
          type: 'LINE_ITEM',
          price: event.campaign.price.toString(),
          status: 'FINAL'
        }
      ],
      countryCode: 'BR',
      currencyCode: 'BRL',
      totalPriceStatus: 'FINAL',
      totalPrice: event.campaign.price.toString(),
      totalPriceLabel: 'Total',
      checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE'
    }

    this.plan_title = event.campaign.name;
    this.variation_id = event.campaign.id;
  }
}
