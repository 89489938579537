import BaseRequestBuilder from "./base-request-builder";

/**
 * Build an is ready to pay request based on sub fields injected on construtor
 */
export class IsReadyToPayRequestBuilder extends BaseRequestBuilder {
  /**
   * @returns {google.payments.api.IsReadyToPayRequest} that represents the request builded based on injected providers
   */
  public getRequest(): google.payments.api.IsReadyToPayRequest {

    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: this.getAllowedPaymentMethods()
    }
  }
}
