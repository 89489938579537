import Frontend from "helpers/frontend";
import JCButton from "../../components/jc-button";
import Footer from "../../components/footer";
import AppScreen from "components/app-screen";
import FeedbackScreenAttributes from "./feedback-screen-attributes";
import Env from "environment/env";

export default class FeedbackScreen extends AppScreen {

  constructor(protected wrapperElement: Element, protected attributes: FeedbackScreenAttributes) {
    super(wrapperElement);
  }

  public async build(): Promise<HTMLElement> {
    return Frontend.div({ id: Env.iframeElement() }, [
      Frontend.img({ class: "logo-header" }, `${Env.images.baseUrl()}images/logo-sjcc.png`, "Jornal do Commercio"),
      Frontend.div({ class: "content" }, [
        Frontend.img({ class: "" }, this.attributes.iconUrl),
        Frontend.p({ class: "title" }, this.attributes.title),
        Frontend.p({ class: "description" }, this.attributes.description),
        await this.viewPlansButton(),
      ]),
      await this.footer(),
    ]);
  }

  private async viewPlansButton(): Promise<HTMLElement> {
    const button = new JCButton<void>({ id: "", text: this.attributes.buttonText, class: "btn-feedback" });
    Frontend.makeStyle(button.style());
    const element = await button.build();
    this.addClickEvent(element);
    return element;
  }

  private async addClickEvent(button: HTMLElement) {
    button.addEventListener('click', async () => {
      await this.attributes.callback();
    });
  };

  private async footer(): Promise<HTMLElement> {
    const footer = new Footer("transparent");
    Frontend.makeStyle(footer.style());
    return await footer.build();
  }

  public style(): string {
    return `
    #${Env.iframeElement()} {
      width: 100%;
      display: block;
      min-height: 330px;
      box-sizing: border-box !important;
      background: #F7F7F7;
      font-family: "Open Sans", sans-serif !important;
      font-size: 14px;
      margin: 0;
    }
    @media (min-width: 768px) {
      #${Env.iframeElement()} {
        font-size: 16px;
        max-width: 1440px;
        margin: 0 auto;
      }
    }

    #${Env.iframeElement()} .logo-header {
      margin: 0 auto;
      padding: 4em 0 0;
      display: block;
      max-width: 55%;
    }

    #${Env.iframeElement()} .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8em 1em;
    }

    #${Env.iframeElement()} .content .title {
      padding: 0 0 1em;
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      margin-top: 4em;
    }

    #${Env.iframeElement()} .content .description {
      font-size: 14px;
      font-weight: normal;
      text-align: center;
      line-height: 1.3;
      padding: 0 4em;
      margin-top: 0;
      margin-bottom: 4em;
    }

    @media (min-width: 768px) {
      #${Env.iframeElement()} .content .description{
        padding: 0 5em;
      }
    }

    @media (min-width: 1024px) {
      #${Env.iframeElement()} .content .description {
        font-size: 1.3em;
      }

      #${Env.iframeElement()} .content .description {
        padding: 2em;
        font-size: 2em;
      }
    }`;
  }
}
