import WallAttributes from "./attributes/wall-attributes";
import TaximeterAttributes from "./attributes/taximeter-attributes";
import Main from "facades/main";
import Frontend from "helpers/frontend";
import Wall from "walls/wall";
import { LoginStatus } from "concerns/login-status";
import Env from "environment/env";

export default class DisconnectedTaximeter extends Wall {

  protected attributes: TaximeterAttributes;

  public constructor(protected wrapperElement: HTMLElement, protected loginStatus: LoginStatus, attributes: WallAttributes) {
    super(wrapperElement, loginStatus);
    this.attributes = attributes as TaximeterAttributes;
  }

  public async build(): Promise<HTMLElement> {
    if (this.attributes.remaining === null) {
      return;
    }

    // Avoid more than one Taximeter
    if (document.getElementById(Env.taximeterElementId())) {
      return;
    }

    const loginUrl = Main.getLoginUrl();
    const registerUrl = Main.getRegisterUrl();

    const widget = this.createWidget(this.attributes.remaining, loginUrl, registerUrl);

    // Events
    const closeElement = document.getElementById("signin-wall-taximiter-close");

    closeElement.addEventListener("click", (event) => {
      event.preventDefault();
      event.stopPropagation();

      widget.remove();

      const spacer = document.getElementById("signin-wall-taximiter-spacer");
      if (spacer) {
        spacer.remove();
      }

      return false;
    }, false);

    return widget;
  }

  protected async applyScreen(screen: HTMLElement): Promise<void> {
    this.wrapperElement.prepend(screen);
  }

  /**
   * Create the element
   *
   * @param  {number}      remaining
   * @param  {string}      loginUrl
   * @param  {string}      registerUrl
   * @return {HTMLElement}
   */
  protected createWidget(remaining: number, loginUrl: string, registerUrl: string): HTMLElement {

    const remainsElement = this.buildRemainsElement(remaining, registerUrl);
    const loginElement = this.buildLoginElement(loginUrl);
    const closeElement = this.buildCloseElement();

    const widget = Frontend.div({ id: Env.taximeterElementId() }, [
      remainsElement,
      loginElement,
      closeElement,
    ]);

    /**
     * Add a empty div to the body: we cannot trust on
     * padding bottom as site can have it"s owns
     */
    const divFakeSpacer = Frontend.div({ id: "signin-wall-taximiter-spacer" }, [widget]);
    document.body.appendChild(divFakeSpacer);
    return widget;
  }


  protected buildCloseElement() {
    return Frontend.a({ id: "signin-wall-taximiter-close" }, "#", "x");
  }

  protected buildLoginElement(loginUrl: string) {
    return Frontend.p({ id: "signin-wall-taximiter-login" }, [
      "Possui cadastro? ",
      Frontend.a(null, loginUrl, "Faça login aqui"),
    ]);
  }

  protected buildRemainsElement(remaining: number, registerUrl: string) {
    const remainingText = this.buildRemainsText(remaining);
    return Frontend.p(null, [
      remainingText,
      Frontend.create("br"),
      Frontend.a({ id: "signin-wall-taximiter-register" }, registerUrl, "Cadastre-se aqui!"),
    ]);
  }

  protected buildRemainsText(remaining: number) {
    if (remaining <= 0) {
      return "Só mais alguns conteúdos restantes.";
    }
    if (remaining === 1) {
      return "1 conteúdo restante.";
    }
    return `${remaining} conteúdos restantes.`;
  }

  /**
   * Create the stylesheet for taximeter
   *
   * @return {string}
   */
  public style(): string {
    return `#${Env.taximeterElementId()} {
      font-family: "Open Sans", sans-serif !important;
      display: block;
      width: 100%;
      background: #004fa0;
      padding: 27px 10px;
      text-align: center;
      box-sizing: border-box;
      position: fixed;
      margin: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      height: 100px;
    }
    #${Env.taximeterElementId()} br {
      display: none;
    }
    #${Env.taximeterElementId()} p {
      font-family: "Open Sans", sans-serif !important;
      display: block;
      color: #FFFFFF;
      font-size: 16px;
      line-height: 15px;
      font-weight: normal;
      margin: 0;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-register {
      color: #FFFFFF;
      line-height: 15px;
      font-size: 16px;
      font-weight: bold;
      margin: 0 0 0 10px;
      text-decoration: underline;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-login {
      display: block;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 21px;
      font-weight: light;
      margin: 10px 0 0 0;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-login a {
      text-decoration: underline;
      color: #FFFFFF;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-close {
      text-decoration: none;
      color: #FFFFFF;
      font-size: 13px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1px 5px;
    }
    #signin-wall-taximiter-spacer {
      display: block;
      height: 100px;
    }`;
  }

  /**
   * Create the stylesheet for taximeter
   *
   * @return {string}
   */
  public styleMobile(): string {
    return `#${Env.taximeterElementId()} {
      position: relative;
      margin: 20px 0px;
      bottom: auto;
      left: auto;
      right: auto;
      height: auto;
      padding: 5px 10px;
    }
    #${Env.taximeterElementId()} br {
      display: block;
    }
    #${Env.taximeterElementId()} p {
      font-size: 12px;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-register {
      font-size: 12px;
      margin-left: 0;
    }
    #${Env.taximeterElementId()} #signin-wall-taximiter-login {
      font-size: 10px;
      margin-top: 0;
    }
      #signin-wall-taximiter-spacer {
      display: none;
    }`;
  }
}
