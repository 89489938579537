import PaymentFeedbackEvent from "events/payment-feedback-event";
import Analytics from "helpers/analytics";
import Observer from "./observer";

export default class PaymentTracking implements Observer<PaymentFeedbackEvent> {
  constructor(private analytics: Analytics) {}

  public async handle(event: PaymentFeedbackEvent): Promise<void> {
    this.analytics.send({
      event: 'paywall_subscription',
      paywall_subscription_status: event.payment_status,
      paywall_subscription_product_name: event.payment.plan_title,
      paywall_subscription_product_id: event.payment.variation_id,
      paywall_subscription_payment_type: event.payment.type
    });
  }
}
