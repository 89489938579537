import * as URI from "urijs";

import Logger from "helpers/logger";
import JSONWrapper from "helpers/json-wrapper";
import { Campaign } from "concerns/campaign";
import { SubscribeAPIResponse } from "concerns/subscribe-api-response";
import { SubscribeApiPayment } from "concerns/subscribe-api-payment";
import { SubscribeAPIApplePayPaymentSessionRequest } from "concerns/subscribe-api-applepay-payment-session-request";
import { SubscribeAPIApplePayPaymentSessionResponse } from "concerns/subscribe-api-applepay-payment-session-response";

export default class SubscribeApi {

  public constructor(readonly baseUrl: string, private apiToken: string) { }

  /**
   * Process the Payment
   *
   * @param  {SubscribeApiPayment} payment
   * @return {Promise<SubscribeAPIResponse<T>>|null}
   */
  public async processPayment(payment: SubscribeApiPayment): Promise<SubscribeAPIResponse<Object>> {
    const url = this.buildUrl("signinwall/process-payment");
    const request = await fetch(url, {
      body: JSONWrapper.stringify(payment),
      headers: this.buildHeaders(),
      method: "POST"
    });

    return this.processResponse<SubscribeAPIResponse<Object>>(request.json());
  }

  /**
   * List all assine service campaigns
   *
   * @return {Promise<SubscribeAPIResponse<{ [key: string]: Campaign }>>|null}
   */
  public async getCampaigns(campaignIds: string[] = null): Promise<SubscribeAPIResponse<Campaign[]>> {
    const filter = campaignIds === null ? '' : `?ids=${campaignIds.join(',')}`;
    const url = this.buildUrl(`signinwall/get-plans${filter}`);
    const request = await fetch(url, {
      headers: this.buildHeaders(),
      method: "GET"
    });

    return this.processResponse<Campaign[]>(request.json());
  }

  /**
   * Request ApplePay Payment Session
   *
   * @param {SubscribeAPIApplePayPaymentSessionRequest} request
   * @return {Promise<SubscribeAPIResponse<SubscribeAPIResponse>>|null}
   */
   public async getApplePayPaymentSession(paymentSessionRequest: SubscribeAPIApplePayPaymentSessionRequest): Promise<SubscribeAPIResponse<SubscribeAPIApplePayPaymentSessionResponse>> {
    const url = this.buildUrl(`signinwall/request-applepay-payment-session`);
    const request = await fetch(url, {
      body: JSONWrapper.stringify(paymentSessionRequest),
      headers: this.buildHeaders(),
      method: "POST"
    });

    return this.processResponse<SubscribeAPIApplePayPaymentSessionResponse>(request.json());
  }

  /**
   * Process a received response from API and returns the expected parsed result or null;
   *
   * @param  {Promise<any>} response
   * @return {Promise<SubscribeAPIResponse<T>>}
   */
  private processResponse<T>(response: Promise<any>): Promise<SubscribeAPIResponse<T>> {
    return response.then((payload: any) => {
      if (! payload.code) {
        payload.code = 'error';
      }

      return payload as SubscribeAPIResponse<T>;
    }).catch((error): null => {
      Logger.debug("processResponse error:");
      Logger.debug(error);
      return null;
    });
  }

  /**
   * Build a URL from base with given path and params
   *
   * @param  {string} path
   * @param  {any}    params
   * @return {string}
   */
  private buildUrl(path: string, params?: any): string {
    if (typeof params !== "object") {
      params = {};
    }
    return URI(this.baseUrl + "/" + path).addQuery(params).normalize().toString();
  }

  /**
   * Returns the default assinantes API request headers.
   *
   * @return {any}
   */
  private buildHeaders(): any {
    return {
      "Authorization": `Bearer ${this.apiToken}`,
      "Content-Type": "application/json",
    };
  }
}
