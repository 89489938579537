import PlanSelectEvent from 'events/plan-select-event';
import Observer from 'observers/observer';
import Frontend from 'helpers/frontend';
import Format from 'helpers/format';

export default class FillPaymentResume implements Observer<PlanSelectEvent> {
  public async handle(event: PlanSelectEvent): Promise<void> {
    const title = document.getElementById("resume-title");
    const total = document.getElementById("resume-total");
    const totalHidden = document.getElementById("resume-total-value") as HTMLInputElement;

    title.innerHTML = "";
    totalHidden.value = event.campaign.price.toString();

    title.appendChild(Frontend.span({}, event.campaign.name));
    title.appendChild(Frontend.span({}, `R$ ${Format.price(event.campaign.price)} / ${event.campaign.subscription_type}`));

    total.innerHTML = "R$ " + Format.price(event.campaign.price);
  };
}
