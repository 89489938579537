import Frontend from "helpers/frontend";
import Button from "./button";
import Env from "environment/env";

/**
 * Creates a button component formated using JC project layout
 */
export default class JCButton<T> extends Button<T> {

  public constructor(protected data: { text: string, class: string, id: string, type?: string }) {
    super();
  }

  public async build(): Promise<HTMLElement> {
    return Frontend.button({ id: this.data.id, class: this.data.class, type: this.data.type }, this.data.text);
  }

  style(): string {
    return `
    #${Env.iframeElement()} .${this.data.class} {
      display: block;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 2;
      border-radius: 999px;
      border: 1px solid #fb0021;
      color: #fff;
      background-color: #fb0021;
      padding: 0.3em 1.5em;
      margin: 0 auto;
      cursor: pointer;
      outline: none;
      text-decoration: none;
      max-width: 300px;
    }`;
  }
}
