import Frontend from "helpers/frontend";
import FillInstallmentOptions from "observers/fill-installment-options";
import FillPaymentResume from "observers/fill-payment-resume";
import FillResumeInstallment from "observers/fill-resume-installment";
import GooglePay from "payment/google/google-pay";
import ApplePay from "payment/apple/apple-pay";
import PlanCard from "components/plan-card";
import Footer from "components/footer";
import PaymentScreen from "observers/payment-screen";
import EWalletAvailability from "observers/ewallet-availability";
import PaymentProcessor from "observers/payment-processor";
import AppScreen from "components/app-screen";
import PaywallAttributes from "walls/attributes/paywall-attributes";
import FormPay from "payment/form/form-pay";
import Form from "./components/form";
import OrderResume from "./components/order-resume";
import Component from "components/component";
import Env from "environment/env";
import Pattern from "helpers/pattern";
import NumericMaskedInput from "components/numeric-masked-input";
import { Campaign } from "concerns/campaign";
import Main from "facades/main";
import PaymentTracking from "observers/payment-tracking";
import { CurrentState } from "state/state";
import Analytics from "helpers/analytics";

export default class CheckoutScreen extends AppScreen {
  private googlePay: GooglePay;
  private applePay: ApplePay;
  private submitForm: FormPay;
  private paymentProcessor: PaymentProcessor;
  private creditCardForm: Form;
  private orderResume: OrderResume;
  private analytics: Analytics;

  private ewalletAvailabilityObserver: EWalletAvailability;
  private paymentScreenObserver: PaymentScreen;

  public constructor(protected wrapperElement: Element, protected attributes: PaywallAttributes) {
    super(wrapperElement);

    this.analytics = new Analytics(attributes.tracking_metadata);

    this.submitForm = new FormPay();
    this.googlePay = new GooglePay(this.shouldUseEwallets());
    this.applePay = new ApplePay(this.shouldUseEwallets());

    this.creditCardForm = new Form();
    this.creditCardForm.subscribe(new FillResumeInstallment());

    this.orderResume = new OrderResume();

    this.paymentProcessor = new PaymentProcessor(this.wrapperElement, this);
    this.paymentProcessor.subscribe(new PaymentTracking(this.analytics));
    this.paymentProcessor.subscribe(this.applePay);

    this.submitForm.subscribe(this.paymentProcessor);
    this.googlePay.subscribe(this.paymentProcessor);
    this.applePay.subscribe(this.paymentProcessor);

    this.ewalletAvailabilityObserver = new EWalletAvailability();
    this.googlePay.subscribe(this.ewalletAvailabilityObserver);
    this.applePay.subscribe(this.ewalletAvailabilityObserver);

    this.paymentScreenObserver = new PaymentScreen(this.analytics);
  }

  public async build(): Promise<HTMLElement> {
    return Frontend.div(
      { id: Env.iframeElement() }, [
      await this.buildInitialPageWithLoadingBadge(),
    ]);
  }

  protected async postApplyScreen(): Promise<void> {
    if (this.shouldUseEwallets()) {
      this.googlePay.createButton();
      this.applePay.createButton();
    }

    const plans = await this.planCardList();
    this.completePageWithFetchedData(plans);
    this.createAccordionEffect();
  }

  private shouldUseEwallets(): boolean {
    return this.attributes.paywall_ewallets.toString() === '1';
  }

  private completePageWithFetchedData(plans: HTMLElement) {
    const divPlansElement = document.getElementById("plans");
    divPlansElement.classList.remove("loading");
    divPlansElement.innerHTML = '';
    divPlansElement.appendChild(plans);

    // Analytics
    this.analytics.send({event: 'paywall_screen', screen: 'plans'});
  }

  private async buildInitialPageWithLoadingBadge(): Promise<HTMLElement> {
    return Frontend.div(
      { class: "checkout" },
      [
        ...this.header(),
        Frontend.div({ id: "plans", class: "loading" }, [
          Frontend.div({}),
        ]),
        await this.paymentContent(),
        await this.footer(),
      ],
    );
  }

  private header(): HTMLElement[] {
    return [
      Frontend.img({ class: "logo-header" }, `${Env.images.baseUrl()}images/logo-sjcc.png`, "Jornal do Commercio"),
      Frontend.ul({}, [
        Frontend.li({ id: "checkout-screen-selector-plans", class: "active-line" }, "Planos"),
        Frontend.li({ id: "checkout-screen-selector-payment" }, "Pagamento")
      ]),
      Frontend.header({}, [
        Frontend.span({}, "Olá, " + CurrentState.user.getFirstName()),
        Frontend.p({ class: "active-line" }, "Selecione o seu plano"),
      ])
    ];
  }

  private async planCardList(): Promise<HTMLElement> {
    const campaigns = await this.fetchCampaignList([
      this.attributes.paywall_first_offer,
      this.attributes.paywall_second_offer,
      this.attributes.paywall_third_offer,
    ]);

    const array = [];
    let count = 0;

    if (!campaigns || !campaigns.length) {
      return Frontend.div(null, []);
    }

    for (const campaign of campaigns) {
      array.push(await this.planCard(campaign, count === 0));
      count++;
    }

    return Frontend.div({ class: "cards-wrapper" }, array);
  }

  private async fetchCampaignList(campaignIds: string[]): Promise<Campaign[]> {
    const response = await Main.getSubscribeApi().getCampaigns(campaignIds);
    return response ? response.data : [];
  }

  private async backToPlansButton(): Promise<HTMLElement> {
    const onclick = () => {
      this.paymentScreenObserver.showPlanSelectScreen();
      return false;
    };

    return Frontend.p({ class: 'back-to-plans' }, [
      "ou",
      Frontend.a({ onclick: onclick }, "#", "escolha outra plano"),
    ])
  }

  private async planCard(campaign: Campaign, isPopular: boolean): Promise<HTMLElement> {
    const planCard = new PlanCard(campaign, isPopular);
    planCard.subscribe(new FillInstallmentOptions());
    planCard.subscribe(new FillPaymentResume());
    planCard.subscribe(this.paymentScreenObserver);
    planCard.subscribe(this.ewalletAvailabilityObserver);
    planCard.subscribe(this.submitForm);
    planCard.subscribe(this.applePay);
    planCard.subscribe(this.googlePay);
    Frontend.makeStyle(planCard.style());

    return planCard.build();
  }

  private async paymentContent(): Promise<HTMLElement> {
    const subscribeButton = Frontend.div({ id: "assine-button" }, [
      Frontend.p({}, "Clique no botão abaixo para concluir seu pagamento no Assine JC."),
      Frontend.a({ class: "btn-assine", id: "assine-link" }, "", "Assinar"),
      await this.backToPlansButton()
    ]);

    const form = await this.form();

    return Frontend.div({ class: "payment-wrapper", id: "payment-wrapper" }, [
      subscribeButton,
      form,
    ]);
  }

  private async form(): Promise<HTMLElement> {
    const formElement = Frontend.form({ id: "checkout-form", autocomplete: "on" }, [
      Frontend.div({}, [
        await this.userData(),
        await this.paymentForm(),
      ]),
      Frontend.div({}, [
        await this.buildAndApplyComponentStyle(this.orderResume),
        await this.buildAndApplyComponentStyle(this.submitForm)
      ]),
      Frontend.div({}, [ await this.backToPlansButton() ])
    ]);

    formElement.addEventListener('submit', (event) => {
      event.preventDefault();
    });

    return formElement;
  }

  private async userData(): Promise<HTMLElement> {
    const cpf = new NumericMaskedInput({
      id: "user-data-cpf",
      name: "cpf",
      pattern: Pattern.cpf(),
      required: true,
      autocomplete: "",
      placeholder: "CPF",
      parentClass: "input-container",
      "data-format": "***.***.***-**",
    });

    const telephone = new NumericMaskedInput({
      id: "user-data-telephone",
      name: "telephone",
      pattern: Pattern.contactPhone(),
      required: true,
      autocomplete: "",
      placeholder: "Telefone",
      parentClass: "input-container",
      "data-format": "(**) *****-****",
    });

    return Frontend.div({ class: "user-data" }, [
      Frontend.header({}, [
        Frontend.p({}, "Preencha os seus dados"),
      ]),
      Frontend.div({ class: "input-container" }, [
        await this.buildAndApplyComponentStyle(telephone),
      ]),
      Frontend.div({ class: "input-container" }, [
        await this.buildAndApplyComponentStyle(cpf),
      ]),
    ]);
  }

  private async paymentForm(): Promise<HTMLElement> {
    const creditCard = await this.buildAndApplyComponentStyle(this.creditCardForm);

    return Frontend.div({}, [
      Frontend.div({id: "ewallets-wrapper", class: "hidden"}, [
        Frontend.div({id: "ewallets-buttons"}),
        Frontend.p({ class: "or-separator" }, "ou pague com cartão de crédito"),
      ]),
      Frontend.header({ id: "credit-card-form-header", class: "payment-header" }, [
        Frontend.p({ class: "active-line" }, "Dados de Pagamento"),
      ]),
      creditCard,
    ]);
  }

  private async buildAndApplyComponentStyle(component: Component): Promise<HTMLElement> {
    const element = await component.build();
    Frontend.makeStyle(component.style());
    return element;
  }

  private async footer(): Promise<HTMLElement> {
    const footer = new Footer("transparent");
    Frontend.makeStyle(footer.style());
    return await footer.build();
  }

  private createAccordionEffect(): void {
    const elements = document.getElementsByClassName("accordion");

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", function () {
        let panel = this.nextElementSibling;
        if (panel.style.display === "flex") {
          panel.style.display = "none";
        } else {
          panel.style.display = "flex";
        }
      });
    }
  }

  public style(): string {
    return `#${Env.iframeElement()} {
      width: 100%;
      display: block;
      min-height: 330px;
      padding: 30px 0 0;
      box-sizing: border-box !important;
      text-align: center !important;
      background-image: linear-gradient(270deg, #ffffff 0%, #f1f1f1 100%);
      font-family: "Open Sans", sans-serif !important;
      font-size: 14px;
    }

    #${Env.iframeElement()} img {
      max-width: 100%;
      width: auto;
    }

    #${Env.iframeElement()} .paywall-text-wrapper {
      max-width: 100% !important;
      display: block;
      margin: 0!important;
      padding: 0 20px !important;
      box-sizing: border-box !important;
    }
    #${Env.iframeElement()} .logo-header {
      margin: 0 auto;
      display: block;
      margin-top: 0;
      max-width: 220px;
    }

    #${Env.iframeElement()}  ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 3em 0;
    }

    #${Env.iframeElement()} ul > li {
        padding: 0 2em;
        padding-bottom: 1em;
        position: relative;
    }

    #${Env.iframeElement()} .active-line:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 37%;
        border-bottom: 3px solid #fb0021;
        border-radius: 999px;
        width: 25%;
    }

    #${Env.iframeElement()} header {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #${Env.iframeElement()} header span {
        font-weight: bold;
    }

    #${Env.iframeElement()} header p {
        position: relative;
        padding: 1em 0;
    }

    #${Env.iframeElement()} #plans {
      animation: fadeEffect 0.4s linear;
    }

    #${Env.iframeElement()} .payment-wrapper {
      padding: 0 1.8em;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    #${Env.iframeElement()} .payment-wrapper.has-loading {
      display: flex;
      align-items: center;
    }

    #${Env.iframeElement()} .payment-wrapper.has-loading #assine-button,
    #${Env.iframeElement()} .payment-wrapper.has-loading #checkout-form {
      display: none;
    }

    #${Env.iframeElement()} .payment-wrapper form {
      display: none;
      animation: fadeEffect 0.4s linear;
      flex-direction: column;
    }

    #${Env.iframeElement()} .payment-wrapper form header {
      display: flex;
    }

    #${Env.iframeElement()} .payment-wrapper form .payment-header p {
      padding-top: 0;
      margin-bottom: 2em;
    }

    #${Env.iframeElement()} .payment-wrapper form #ewallets-wrapper.hidden,
    #${Env.iframeElement()} .payment-wrapper form #credit-card-form-header.hidden {
      display: none!important;
    }

    #${Env.iframeElement()} .payment-wrapper form .or-separator {
      margin: 2em 0;
      display block;
    }

    #${Env.iframeElement()} .payment-wrapper form #ewallets-buttons > div + div {
      margin-top: 1rem;
    }

    #${Env.iframeElement()} .payment-wrapper .show-form {
      display: block;
    }

    #${Env.iframeElement()} #assine-button {
      display: none;
      animation: fadeEffect 0.4s linear;
      flex-direction: column;
      align-items: center;
    }

    #${Env.iframeElement()} .loading {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 100px 0;
    }

    #${Env.iframeElement()} .loading:after {
      content: '';
      display: block;
      width: 34px;
      height: 34px;
      margin: 12px 0;
      border-radius: 50%;
      border: 6px solid #fb0021;
      border-color: #fb0021 transparent #fb0021 transparent;
      animation: loading 1.2s linear infinite;
    }

    #${Env.iframeElement()} .btn-assine {
      display: block;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 2;
      border-radius: 999px;
      border: 1px solid #fb0021;
      color: #fff;
      background-color: #fb0021;
      padding: .3em 1.5em;
      margin: 0 auto;
      margin-top: 2em;
      cursor: pointer;
      text-decoration: none;
      outline: 0;
    }

    #${Env.iframeElement()} .payment-tab-menu {
      margin: 2em 0;
    }

    #${Env.iframeElement()} .payment-tab-menu li {
      cursor: pointer;
    }

    #${Env.iframeElement()} .hidden-fade-out {
      position: absolute;
      opacity: 0;
      transition: position .3s linear;
    }

    #${Env.iframeElement()} .visible-fade-in {
      position: static;
      opacity: 1;
      transition: opacity .3s linear;
    }

    #${Env.iframeElement()} .user-data {
      padding: 0 0 1em;
      margin-bottom: 1em;
    }

    #${Env.iframeElement()} .user-data > header {
      padding-bottom: 1em;
    }

    #${Env.iframeElement()} .user-data > header p {
      padding: 0;
      margin-top: 0;
    }

    #${Env.iframeElement()} .back-to-plans {
      flex-direction: column;
      display: flex;
      margin: 2em 0 0;
    }

    #${Env.iframeElement()} .back-to-plans a {
      margin: 0.5em;
      color: inherit;
      text-decoration: none;
      font-weight: bold;
    }

    @media (min-width: 768.1px) {
      #${Env.iframeElement()} .payment-wrapper {
        padding-bottom: 5em;
        align-items: unset;
      }

      #${Env.iframeElement()} .payment-wrapper form {
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }

      #${Env.iframeElement()} .payment-wrapper .show-form {
        display: flex;
      }
    }

    @keyframes fadeEffect {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }`;
  }
}
