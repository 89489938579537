
import Main from "facades/main";
import Frontend from "helpers/frontend";
import { LoginStatus } from "concerns/login-status";
import DisconnectedTaximeter from "./disconnected-taximeter";
import WallAttributes from "./attributes/wall-attributes";
import { CurrentState } from "state/state";

export default class ConnectedTaximeter extends DisconnectedTaximeter {

  public constructor(protected wrapperElement: HTMLElement, protected loginStatus: LoginStatus, attributes: WallAttributes) {
    super(wrapperElement, loginStatus, attributes);
    CurrentState.user = this.loginStatus.userinfo;
  }

  protected buildLoginElement(loginUrl: string) {
    const email = CurrentState.user.getEmail();
    if (!email) {
      return;
    }
    loginUrl = Main.getReauthenticateUrl();

    return Frontend.p({ id: "signin-wall-taximiter-login" }, [
      "Não é <strong>" + email + "</strong>? ",
      Frontend.a(null, loginUrl, "Faça login novamente"),
      ".",
    ]);
  }

  protected buildRemainsElement(remaining: number, registerUrl: string) {
    const paywallUrl = '';
    const remainingText = this.buildRemainsText(remaining);
    return Frontend.p(null, [
      (remaining > 0) ? remainingText : "Só mais alguns conteúdos gratuitos restantes.",
      Frontend.create("br"),
      paywallUrl ? Frontend.a({ id: "signin-wall-taximiter-register" }, paywallUrl, "Assine aqui!") : "",
    ]);
  }
}
