export default class Pattern {
  public static cpf(): string {
    return "^([0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2})|([0-9]{11})$";
  };

  public static contactPhone(): string {
    return "^\\([1-9]{2}\\) ([0-9]{4}|[0-9]{5})-[0-9]{4}$";
  };

  public static creditCard(): string {
    return "^[\\.|\\d]+$";
  }

  public static ccName(): string {
    return "^[\\w|\\s]+$";
  }

  public static ccExpirationMonth(): string {
    return "^[0-9]{2}$";
  }

  public static ccExpirationYear(): string {
    return "^[0-9]{4}$";
  }

  public static ccCsc(): string {
    return "^[0-9]{3}$";
  }
}
