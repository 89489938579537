export default class SignInWallInitializer {
  constructor(private SignInWall: any) { }

  /**
   * Initialize SignInWall
   *
   */
  public init(wrapperId: string) {
    return this.SignInWall.init(wrapperId);
  }
}
