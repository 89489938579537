
import Frontend from 'helpers/frontend';
import PlanSelectEvent from 'events/plan-select-event';
import Observer from 'observers/observer';

export default class FillInstallmentOptions implements Observer<PlanSelectEvent> {
  public async handle(event: PlanSelectEvent): Promise<void> {
    const installmentsElement = document.getElementById("installments");
    const buttons = document.getElementsByClassName("btn-plan");
    const resumeInstallment = document.getElementById("resume-installments");

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('btn-active');
      buttons[i].innerHTML = "SELECIONAR";
    }

    event.button.classList.add('btn-active');
    event.button.innerHTML = "SELECIONADO";

    resumeInstallment.innerHTML = "Selecione as parcelas";
    installmentsElement.innerHTML = "";
    installmentsElement.classList.add('default');

    const firstOption = Frontend.option({ value: "", disabled: "disabled", selected: "selected" }, "Parcelas");
    installmentsElement.appendChild(firstOption);
    event.campaign.installments.forEach(installment => {
      installmentsElement.appendChild(Frontend.option({ value: installment }, `${installment}`));
    });
  };
}
