import Main from "facades/main";

class Index {
  /**
   * Start everything
   */
  public static init(): void {
    if (this.initializingByAsyncMethod()) {
      const asyncInit: any = ((window as any).SJCC_Login as any).asyncInit;

      (window as any).SJCC_Login = Main;
      asyncInit();
      return;
    }

    if (this.initialized()) {
      return;
    }

    (window as any).SJCC_Login = Main;
  }

  /**
   * Check the main object is undefined
   *
   * @return {boolean}
   */
  private static isUndefined(): boolean {
    return (window as any).SJCC_Login === undefined;
  }

  /**
   * Main object can be not undefined but still not defined by SDK.
   * For example, on asyncInit.
   *
   * @return {boolean}
   */
  private static initialized(): boolean {
    if (this.isUndefined()) {
      return false;
    }

    return typeof (window as any).SJCC_Login.init === "function";
  }

  /**
   * Check the main object has 'asyncInit' method.
   * It's only presented on async implementation.
   *
   * @return {boolean}
   */
  private static initializingByAsyncMethod(): boolean {
    if (this.isUndefined()) {
      return false;
    }

    return (typeof ((window as any).SJCC_Login as any).asyncInit) === "function";
  }
}

Index.init();
