import JCButton from "./jc-button";
import Env from "environment/env";

export default class UnselectedButton<T> extends JCButton<T> {
  public style(): string {
    return `
    #${Env.iframeElement()} .${this.data.class} {
      text-transform: uppercase;
      font-weight: 900;
      line-height: 2;
      background-color: #fff;
      border-radius: 999px;
      border: 1px solid #fb0021;
      color: #fb0021;
      padding: 0.5em 1.5em;
      margin-bottom: 2em;
      cursor: pointer;
      outline: none;
      text-decoration: none;
    }

    #${Env.iframeElement()} .${this.data.class}.btn-active {
      color: #fff;
      background-color: #fb0021;
    }`
  }
}
