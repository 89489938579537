import SignInWallAttributes from "./attributes/signinwall-attributes";
import WallAttributes from "./attributes/wall-attributes";
import Main from "facades/main";
import Frontend from "helpers/frontend";
import Wall from "walls/wall";
import { LoginStatus } from "concerns/login-status";
import Env from "environment/env";

export default class SignInWall extends Wall {

  private attributes: SignInWallAttributes;

  public constructor(
    protected wrapperElement: Element,
    protected loginStatus: LoginStatus,
    attributes: WallAttributes
  ) {
    super(wrapperElement, loginStatus);
    this.attributes = attributes as SignInWallAttributes;
  }

  public async build(): Promise<HTMLElement> {
    return Frontend.div(
      { id: Env.iframeElement() },
      [
        Frontend.div(null, [
          Frontend.p({ class: "text-italic" }, (this.attributes.blocked_text || "")),
          Frontend.p(null, this.description()),
          Frontend.a(null, await this.registerUrl(), "Cadastre-se"),
          Frontend.create("hr"),
          Frontend.p({ class: "text-login" }, this.loginText()),
          Frontend.a({ class: "login" }, await this.loginUrl(), "Login"),
        ])
      ]);
  }

  private description(): string {
    return "Cadastre-se e use uma <strong>única conta</strong> " +
      "para acessar todos os serviços do Sistema Jornal do Commercio" +
      " de Comunicação. É rápido, fácil e <strong>grátis</strong>!";
  }

  private loginText(): string {
    return "Se você for assinante do JC ou já possuir cadastro, faça seu login abaixo:";
  }

  private async registerUrl(): Promise<string> {
    return await Main.getRegisterUrl();
  }

  private async loginUrl(): Promise<string> {
    return await Main.getLoginUrl();
  }

  public style(): string {
    return `
    #${Env.iframeElement()} {
        width: 100%;
        border: 2px solid rgba(0, 0, 0, 0.2);
        display: block;
        min-height: 330px;
        padding: 40px 0 60px;
        box-sizing: border-box !important;
    }
    #${Env.iframeElement()} > div {
        text-align: center !important;
        width: 470px !important;
        max-width: 100% !important;
        display: block;
        margin: 0 auto !important;
        padding: 0 20px !important;
        box-sizing: border-box !important;
    }
    #${Env.iframeElement()} p {
        font-family: "Open Sans", sans-serif !important;
        color: #3d3d3d;
        font-size: 16px;
        margin: 0 0 30px !important;
        font-weight: 400 !important;
    }
    #${Env.iframeElement()} p.text-italic {
        margin: 0 0 30px !important;
        font-weight: 600 !important;
        font-style: italic !important;
    }
    #${Env.iframeElement()} p.text-login {
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 360px !important;
    }
    #${Env.iframeElement()} a {
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
        text-transform: uppercase !important;
        font-weight: bold !important;
        line-height: 18px !important;
        padding: 10px 20px !important;
        min-width: 200px !important;
        border-radius: 8px !important;
        border: none !important;
        color: #FFFFFF !important;
        box-shadow: none !important;
        background: #e50006 !important;
        user-select: none !important;
        text-decoration: none !important;
        text-align: center !important;
        white-space: nowrap !important;
        display: inline-block !important;
    }
    #${Env.iframeElement()} a.login {
        background: #004fa0 !important;
    }
    #${Env.iframeElement()} hr {
        border: 0 !important;
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
        margin: 30px 0 !important;
        box-sizing: content-box !important;
        height: 0 !important;
        overflow: visible !important;
    }`;
  }
}
