import * as URI from "urijs";
import WallAttributes from "./attributes/wall-attributes";
import Frontend from "helpers/frontend";
import Device from "helpers/device";
import Browser from "helpers/browser";
import Wall from "walls/wall";
import PaywallAttributes from "./attributes/paywall-attributes";
import Main from "facades/main";
import { LoginStatus } from "concerns/login-status";
import LoginStatusStatus from "concerns/login-status-status";
import Footer from "components/footer";
import JCButton from "components/jc-button";
import CheckoutScreen from "screens/checkout/checkout-screen";
import AppScreen from "components/app-screen";
import Env from "environment/env";
import Component from "components/component";
import { CurrentState } from "state/state";

export default class PayWall extends Wall {
  private attributes: PaywallAttributes;
  private checkoutScreen: AppScreen;

  public constructor(
    protected wrapperElement: Element,
    protected loginStatus: LoginStatus,
    attributes: WallAttributes
  ) {
    super(wrapperElement, loginStatus);
    this.attributes = attributes as PaywallAttributes;
    this.checkoutScreen = new CheckoutScreen(wrapperElement, this.attributes);
  }

  public async build(): Promise<HTMLElement> {
    await this.showCheckoutScreen();
    return this.showBlockScreen();
  }

  private async showCheckoutScreen() {
    const hasParameter = Browser.hasParam("paywall", "plans");

    await Main.getLoginStatus((response: any) => {
      const status = response as LoginStatus;

      CurrentState.user = response.userinfo;

      if (status.status === LoginStatusStatus.CONNECTED && hasParameter) {
        Browser.removeParam("paywall");
        return this.checkoutScreen.load();
      }
    });
  }

  private async showBlockScreen(): Promise<HTMLElement> {
    const userinfo = CurrentState.user;
    const loginText = userinfo ? 'Não é <strong>' + userinfo.getEmail() + '</strong>?' : 'Já é assinante?';
    const loginButtonText = userinfo ? 'Faça login novamente' : 'Faça o seu login aqui';

    const image = this.getImage();

    return Frontend.div(
      { id: Env.iframeElement() },
      [
        Frontend.div(
          { class: 'paywall-block' },
          [
            Frontend.img({ class: "logo-header" }, `${Env.images.baseUrl()}images/logo-sjcc.png`, "Jornal do Commercio"),
            Frontend.h4({ class: "active" }, this.attributes.blocked_title || "O seu conteúdo grátis acabou"),
            Frontend.div({ class: "content" }, [
              Frontend.div({ class: "description" }, [
                Frontend.div({ class: "blocked-text" }, [
                  Frontend.p({}, this.attributes.blocked_text),
                  await this.viewPlansButton(),
                  Frontend.p({ class: "login-text" }, loginText),
                  await this.subscribeLoginButton(loginButtonText),
                ]),
                image ? Frontend.img({ class: "img-fluid" }, image, "Jornal do Commercio") : null,
              ]),
            ]),
            await this.footer(),
          ])
      ]);
  }

  private getImage(): string {
    if (Device.isMobile()) {
      return this.attributes.paywall_image_mobile;
    }

    return this.attributes.paywall_image;
  }

  private async footer(): Promise<HTMLElement> {
    const footer = new Footer("#bbd1e6");
    Frontend.makeStyle(footer.style());
    return await footer.build();
  }

  private async viewPlansButton(): Promise<HTMLElement> {
    const button = new JCButton<void>({
      id: "view-plans",
      text: "Ver planos",
      class: "btn-view-plans"
    });
    const element = await this.buildAndApplyComponentStyle(button);
    element.addEventListener('click', () => {
      this.onClick();
    });
    return element;
  }

  private async subscribeLoginButton(text: string): Promise<HTMLElement> {
    const button = new JCButton<void>({
      id: "login-login",
      text: text,
      class: "btn-login"
    });

    const element = await this.buildAndApplyComponentStyle(button);

    element.addEventListener('click', () => {
      if (this.loginStatus.status === LoginStatusStatus.CONNECTED) {
        Main.reauthenticate();
        return;
      }

      Main.login();
    });

    return element;
  }

  private async buildAndApplyComponentStyle(component: Component): Promise<HTMLElement> {
    const element = await component.build();
    Frontend.makeStyle(component.style());
    return element;
  }

  private onClick() {
    const currentUrl = (window as Window).location.href;
    const redirectUrl: any = URI(currentUrl).addQuery("paywall", "plans");
    Main.getLoginStatus((response: any) => {
      const status = response as LoginStatus;
      if (status.status === LoginStatusStatus.NOT_CONNECTED) {
        (window as Window).location.href = Main.getLoginUrl(redirectUrl);
        return;
      }

      CurrentState.user = response.userinfo;
      this.checkoutScreen.load();
    });
  }

  public style(): string {
    return `
    #${Env.iframeElement()} {
        width: 100%;
        display: block;

        box-sizing: border-box !important;
        background: #fff;
        background-image: linear-gradient(#eaf5fd 0%, #97B4CF 100%);
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px;
        margin: 0;
    }
    @media (min-width: 768px) {
      #${Env.iframeElement()} {
        font-size: 16px;
        max-width: 1440px;
        margin: 0 auto;
      }
    }

    #${Env.iframeElement()} .paywall-block .logo-header {
      margin: 1em auto;
      margin-bottom: .5em;
      display: block;
      max-width: 200px;
      padding: 2em 0;
    }

    #${Env.iframeElement()} .paywall-block .active:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 40%;
      border-bottom: 3px solid #fb0021;
      border-radius: 999px;
      width: 20%;
    }

    #${Env.iframeElement()} .paywall-block .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 2em 0;
    }

    #${Env.iframeElement()} .paywall-block h4 {
      position: relative;
      padding: 0 0 1em;
      font-weight: normal;
      color: #212529;
      width: 20em;
      margin: 0 auto;
      text-align: center;
    }

    #${Env.iframeElement()} .paywall-block .description p {
      font-size: 1.9em;
      font-weight: 700;
      text-align: center;
      line-height: 1.3;
      padding: 0 1em;
      margin-top: 0;
      letter-spacing: 1px;
    }

    #${Env.iframeElement()} .paywall-block .description .login-text {
      display: block;
      font-size: 1em;
      font-weight: 500;
      text-align: center;
      line-height: 1.3;
      padding: 0 1em;
      margin-top: 1rem;
      letter-spacing: 1px;
    }

    @media (min-width: 768px) {
      #${Env.iframeElement()} .paywall-block .description p {
        padding: 0 5em;
      }
    }

    @media (min-width: 1024px) {
      #${Env.iframeElement()} .paywall-block .description {
        flex-direction: row;
        align-items: stretch;
      }

      #${Env.iframeElement()} .paywall-block .description h4 {
        font-size: 1.3em;
      }

      #${Env.iframeElement()} .paywall-block .description p {
        padding: 2em;
        font-size: 1.5em;
      }
    }

    #${Env.iframeElement()} .paywall-block .blocked-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
    }

    #${Env.iframeElement()} .paywall-block .img-fluid {
      max-width: 100%;
      margin: 2em auto;
    }

    @media (min-width: 1024px) {
      #${Env.iframeElement()} .paywall-block .blocked-text {
        max-width: 50%;
        flex-direction: column;
        justify-content: flex-start;
      }

      #${Env.iframeElement()} .paywall-block .img-fluid {
        margin-top: 0;
        max-width: 50%;
        align-self: center;
      }
    }

    @media (min-width: 1024px) {
      #${Env.iframeElement()} .paywall-block .content {
        display: flex;
        align-items: center;
      }
    }`;
  }
}
