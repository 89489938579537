import Observer from "./observer";

import GoogleToSubscribeApiPaymentMap from "payment/map/google-to-subscribe-api-payment-map";
import { PaymentEvent, GPayEvent, ApplePayEvent } from "events/payment-event";
import { SubscribeApiPayment } from "concerns/subscribe-api-payment";
import Main from "facades/main";
import { LoginStatus } from "concerns/login-status";
import AppleToSubscribeApiPaymentMap from "payment/map/apple-to-subscribe-api-payment";
import ErrorFeedbackScreen from "screens/feedback/error-feedback-screen";
import Frontend from "helpers/frontend";
import Session from "helpers/session";
import Logger from "helpers/logger";
import AppScreen from "components/app-screen";
import SuccessFeedbackScreen from "screens/feedback/success-feedback-screen";
import { SubscribeAPIResponse } from "concerns/subscribe-api-response";
import Observable from "./observable";
import PaymentFeedbackEvent from "events/payment-feedback-event";
import { CurrentState } from "state/state";
import Env from "environment/env";

export default class PaymentProcessor extends Observable<PaymentFeedbackEvent> implements Observer<PaymentEvent> {
  constructor(protected wrapperElement: Element, private callbackScreen: AppScreen) {
    super();
  };

  public async handle(event: PaymentEvent): Promise<void> {
    if ((event as ApplePayEvent).token !== undefined) {
      return this.handleApplePayPayment(event as ApplePayEvent);
    }

    if ((event as GPayEvent).paymentMethodData !== undefined) {
      return this.handleGooglePayPayment(event as GPayEvent);
    }

    if ((event as Partial<SubscribeApiPayment>).variation_id !== undefined) {
      return this.handleFormPayment(event as Partial<SubscribeApiPayment>);
    }
  }

  private async handleGooglePayPayment(event: GPayEvent): Promise<void> {
    const mapper = new GoogleToSubscribeApiPaymentMap();
    const internalPayment = mapper.map(event);

    Logger.debug('[GooglePay] Handle Payment Event', internalPayment);
    this.sendPaymentEvent(internalPayment);
  }

  private async handleApplePayPayment(event: ApplePayEvent): Promise<void> {
    const mapper = new AppleToSubscribeApiPaymentMap();
    const internalPayment = mapper.map(event);

    Logger.debug('[ApplePay] Handle Payment Event', internalPayment);
    this.sendPaymentEvent(internalPayment);
  }

  private async handleFormPayment(event: Partial<SubscribeApiPayment>): Promise<void> {
    Logger.debug('[FormPay] Handle Payment Event', event);
    this.sendPaymentEvent(event);
  }

  private async sendPaymentEvent(event: Partial<SubscribeApiPayment>): Promise<void> {
    let accessToken = '';

    await Main.getLoginStatus((loginstatus: LoginStatus) => {
      accessToken = loginstatus.accessToken;
    }, true);

    const payment: SubscribeApiPayment = {
      // required
      type: event.type,
      plan_title: event.plan_title as string,
      variation_id: event.variation_id as number,
      installments: event.installments as number,
      user_identity: event.user_identity as string,
      user_phone: event.user_phone as string,
      // from user
      user_id: CurrentState.user.getId(),
      user_email: CurrentState.user.getEmail(),
      user_fullname: CurrentState.user.getFullname(),
      // from session
      access_token: accessToken,
      // optional
      cc_expiration_date: event.cc_expiration_date,
      cc_holder: event.cc_holder,
      cc_number: event.cc_number,
      cc_security_code: event.cc_security_code,
      wl_token: event.wl_token,
      wl_walletkey: event.wl_walletkey
    };

    await this.loading();

    const response = await Main.getSubscribeApi().processPayment(payment);
    Logger.debug('Process Payment', response);

    const errors = [];
    if (response.code !== 'success') {
      errors.push(response.message);
    }

    await this.notify({ payment_status: response.code, payment, errors } as PaymentFeedbackEvent);

    await this.feedbackScreenFactory(response);

    this.scroolToDiv();
  }

  private async loading() {
    const loading = Frontend.div({ class: "loading" });

    const wrapper = document.getElementById('payment-wrapper');
    wrapper.appendChild(loading);
    wrapper.classList.add('has-loading');
  }

  private scroolToDiv() {
    const offsetTop = document.getElementById(Env.iframeElement()).offsetTop;

    scroll({ top: offsetTop, behavior: "smooth" });
  }

  private async feedbackScreenFactory(response: SubscribeAPIResponse<Object>) {
    if (response.code !== 'success') {
      await this.showErrorScreen();
      return;
    }

    await this.showSuccessScreen();
  }

  private async showSuccessScreen() {
    const errorScreen = new SuccessFeedbackScreen(this.wrapperElement, {
      title: "Assinatura efetuada com sucesso!",
      description: "Agora você tem acesso a todo o conteúdo do nosso portal!",
      buttonText: "voltar para o conteúdo",
      callback: async () => {
        window.location.reload(true);
      }
    });

    Frontend.makeStyle(errorScreen.style());
    await errorScreen.build();

    errorScreen.load();
  }

  private async showErrorScreen() {
    const errorScreen = new ErrorFeedbackScreen(this.wrapperElement, {
      title: "Não foi possível realizar sua assinatura.",
      description: "Por favor, verifique as informações de pagamento e tente novamente.",
      buttonText: "voltar para o pagamento",
      callback: () => this.callbackScreen.load()
    });

    Frontend.makeStyle(errorScreen.style());
    await errorScreen.build();

    errorScreen.load();
  }
}
