import * as URI from "urijs";

import { TokenResponse } from "concerns/token-response";
import { UserinfoResponse } from "concerns/userinfo-response";
import User from "facades/user";
import Frontend from "helpers/frontend";
import Browser from "helpers/browser";
import JSONWrapper from "helpers/json-wrapper";
import Logger from "helpers/logger";
import Session from "helpers/session";
import Env from "environment/env";

export default class ApiAuth {
  private userinfo: any;
  private builtFromUrl: boolean = false;
  private ssoChecked: boolean = false;

  private state: string = Session.generateUUID();

  private defaultHeaders: {} = {
    'Content-Type': 'application/json',
    'X-SJCC-Origin': (window as Window).location.href
  };

  public constructor(readonly baseUl: string, private apiToken: string, readonly type: string) {}

  /**
   * Check for access and refresh tokens on URL.
   * Runs only once.
   *
   * It's a return from API's callback. So save and remove.
   */
  public async buildFromUrl() {
    if (this.builtFromUrl) {
      return;
    }

    this.builtFromUrl = true;

    const currentUrl = (window as Window).location.href;
    const params: any = URI(currentUrl).query(true);

    // Remove access_token, refresh_token and action from URL
    Browser.removeParams(["action", "code", "state"]);

    // I we have action logout, forget code
    if (params.action && params.action === 'logout') {
      Session.forgetState();
      Session.forgetTokens();
      return;
    }

    // If we have code, exchange for tokens
    if (! params.code) {
      return;
    }

    // Check state from API
    const stateIsValid = ! params.state || !Session.validateState(params.state);
    Session.forgetState();

    if (stateIsValid) {
      Logger.error("Invalid state");
      return;
    }

    // Check we already have access token
    const tokens = Session.getTokens();
    if (tokens.access_token) {
      Logger.debug("We do not override session over URL [code] params: call Session.forgetTokens() on login/logout.");
      return;
    }

    const newTokens: TokenResponse = await this.getTokensFromCode(params.code);

    // Set access token
    if (newTokens && newTokens.access_token) {
      Session.setAccessToken(newTokens.access_token);
    }

    if (newTokens && newTokens.refresh_token) {
      Session.setRefreshToken(newTokens.refresh_token);
    }
  }

  /**
   * Check user is already logged but we have not the tokens (like KeycloakJS).
   * Runs only once.
   *
   * It's create a iframe and request for login.
   * If we receive the logged status, create tokens, if not, remove iframe.
   */
  public async checkSso(): Promise<any> {
    if (this.ssoChecked) {
      return null;
    }

    this.ssoChecked = true;

    Logger.debug("Tokens are invalid or not exist: checking SSO directly.");

    return new Promise((resolve, reject) => {
      let fallbackTimeout: number;
      const iframe = Frontend.create("iframe", {
        height: "0px",
        src: this.getStatusIframeUrl(),
        style: "display: none!important",
        title: "keycloak-session-iframe",
        width: "0px",
      }) as HTMLIFrameElement;

      document.body.appendChild(iframe);

      const removeIframe = () => {
        if (!fallbackTimeout) {
          return;
        }

        (window as Window).clearTimeout(fallbackTimeout);
        fallbackTimeout = 0;
        document.body.removeChild(iframe);

        resolve();
      };

      /**
       * Check for iframe status
       */
      (window as Window).addEventListener("message", async (event) => {
        const data = event.data;

        if (event.source !== iframe.contentWindow) {
          return;
        }

        // Status Iframe
        if (typeof data.loginStatus !== "undefined") {
          if (!data.loginStatus) {
            Logger.debug("[checkSso] Removing iframe without find user.");
            removeIframe();
            return;
          }

          const insiderIframeUrl = this.getLoginUrl("status-iframe");
          iframe.contentWindow.postMessage({ loginUrl: insiderIframeUrl }, "*");

          this.addListenerForPostmessage(iframe.contentWindow, (userinfo: any) => {
            Logger.debug("[checkSso] Removing iframe after found user.");
            removeIframe();
          });

          return;
        }
      });

      /**
       * On load, send the clientId.
       *
       * Fallback load: if iframe load but do not receive ready
       * or any message, means maybe we had a problem on loading.
       */
      iframe.addEventListener("load", (event: any) => {
        iframe.contentWindow.postMessage("check-status", "*");

        fallbackTimeout = (window as Window).setTimeout(() => {
          Logger.debug("[checkSso] Iframe took so long.");
          removeIframe();
        }, 5000);
      });
    });
  }

  /**
   * Get Userinfo
   *
   * @return {Promise<any>|null}
   */
  public async getUserInfo(forceLoad?: boolean): Promise<any> {
    const tokens = Session.getTokens();

    if (!tokens.access_token) {
      return null;
    }

    // Check for cached userinfo
    if (!forceLoad && this.userinfo) {
      return this.userinfo;
    }

    // Fetch userinfo from API
    const url = this.buildUrl("auth/userinfo");
    const request = await fetch(url, {
      body: JSONWrapper.stringify(tokens),
      headers: this.defaultHeaders,
      method: "POST",
    });

    return request.json()
      .then((response: UserinfoResponse) => {
        if (response.access_token && response.access_token !== tokens.access_token) {
          Logger.debug("Access Token updated");
          Session.setAccessToken(response.access_token);
        }

        if (response.refresh_token && response.refresh_token !== tokens.refresh_token) {
          Logger.debug("Refresh Token updated");
          Session.setRefreshToken(response.refresh_token);
        }

        if (!response.profile) {
          Session.forgetTokens();
          return null;
        }

        this.userinfo = Object.assign(response, { profile: new User(response.profile) });
        return this.userinfo;
      })
      .catch((error): null => {
        Logger.debug("Userinfo error:");
        Logger.debug(error);

        Session.forgetTokens();

        return null;
      });
  }

  /**
   * Get Userinfo
   *
   * @return {Promise<any>|null}
   */
  public async getTokensFromCode(code: string): Promise<any> {
    // Fetch userinfo from API
    const url = this.buildUrl("auth/token");
    const request = await fetch(url, {
      body: JSONWrapper.stringify({ code }),
      headers: this.defaultHeaders,
      method: "POST",
    });

    return request.json()
      .then((response: any) => {
        if (!response.code || response.code !== 200) {
          return null;
        }

        return response;
      })
      .catch((error): null => {
        Logger.debug("TokensFromCode error:");
        Logger.debug(error);

        return null;
      });
  }

  /**
   * Do a login
   *
   * @return void
   */
  public login() {
    Session.forgetTokens();
    (window as Window).location.href = this.getLoginUrl();
  }

  /**
   * Do a login with Popup
   *
   * @return void
   */
  public loginFromPopup(callback?: Function) {
    Session.forgetTokens();

    const body = document.body;
    const html = document.documentElement;

    const clientWidth = Math.max(
      body.scrollWidth || 0,
      body.offsetWidth || 0,
      html.clientWidth || 0,
      html.scrollWidth || 0,
      html.offsetWidth || 0);
    const left = clientWidth > 500 ? (clientWidth - 500) / 2 : 0;

    const clientHeight = Math.max(
      body.scrollHeight || 0,
      body.offsetHeight || 0,
      html.clientHeight || 0,
      html.scrollHeight || 0,
      html.offsetHeight || 0);
    const top = clientHeight > 700 ? (clientHeight - 700) / 2 : 0;

    const url = this.getLoginUrl("popup");
    const target = "SJCC Login";
    const features = `width=500,height=700,top=${top},left=${left},resizable`;
    const popup = (window as Window).open(url, target, features);
    this.addListenerForPostmessage(popup, callback);
  }

  /**
   * Do logout redirecting to Keycloak
   *
   * @return void
   */
  public logout(redirectUrl?: string) {
    this.logoutTokens().then(() => {
      (window as Window).location.href = this.getLogoutUrl(redirectUrl);
    });
  }

  /**
   * Do logout redirecting to login and back
   *
   * @return void
   */
  public reauthenticate(redirectUrl?: string) {
    Session.forgetTokens();
    (window as Window).location.href = this.getReauthenticateUrl(redirectUrl);
  }

  /**
   * Do logout
   *
   * @return void
   */
  public async logoutTokens() {
    const tokens = Session.getTokens();

    Session.forgetTokens();
    if (! tokens.refresh_token) {
      return;
    }

    const url = this.buildUrl("auth/logout");
    return fetch(url, {
      body: JSONWrapper.stringify(tokens),
      headers: this.defaultHeaders,
      method: "POST",
    }).then((response) => response.json());
  }

  /**
   * Retrieve the loginUrl
   *
   * @return {string}
   */
  public getLoginUrl(redirectUrl?: string): string {
    Session.setState(this.state);

    const currentUrl = (window as Window).location.href;

    const params = {
      origin: currentUrl,
      redirect_url: this.getRediretUrl(redirectUrl),
      state: this.state
    };

    return this.buildUrl("auth", params);
  }

  /**
   * Retrieve the registerUrl
   *
   * @return {string}
   */
  public getRegisterUrl(redirectUrl?: string): string {
    const currentUrl = (window as Window).location.href;

    const params = {
      origin: currentUrl,
      redirect_url: this.getRediretUrl(redirectUrl),
    };

    return this.buildUrl("auth/register", params);
  }

  /**
   * Retrieve the loginUrl
   *
   * @return {string}
   */
  public getAccountUrl(redirectUrl?: string): string {
    const currentUrl = (window as Window).location.href;

    const params = {
      origin: currentUrl,
      redirect_url: this.getRediretUrl(redirectUrl),
    };

    return this.buildUrl("auth/account", params);
  }

  /**
   * Retrieve the loginUrl
   *
   * @return {string}
   */
  public getAccountResourceUrl(resource: string): string {
    const currentUrl = (window as Window).location.href;
    const params = { origin: currentUrl };

    return this.buildUrl("auth/account/" + resource, params);
  }

  /**
   * Retrieve the logoutUrl
   *
   * @return {string}
   */
  public getLogoutUrl(redirectUrl?: string): string {
    const currentUrl = (window as Window).location.href;

    const params = {
      origin: currentUrl,
      redirect_url: this.getRediretUrl(redirectUrl),
    };

    return this.buildUrl("auth/logout", params);
  }

  /**
   * Retrieve the logoutUrl
   *
   * @return {string}
   */
  public getReauthenticateUrl(redirectUrl?: string): string {
    const loginUrl = this.getLoginUrl();
    return this.getLogoutUrl(loginUrl);
  }

  /**
   * Retrieve the 'status-iframe/status-iframe.html' url
   *
   * @return {string}
   */
  public getStatusIframeUrl(): string {
    const currentUrl = (window as Window).location.href;

    return Env.keycloak.baseUrl() + "/realms/" + Env.keycloak.realm()
      + "/status-iframe/status-iframe.html?client_id=" + Env.keycloak.clientId()
      + "&origin=" + currentUrl;
  }

  /**
   * Build a URL from base with given path and params
   *
   * @param  {string} path
   * @param  {any}    params
   * @return {string}
   */
  private buildUrl(path: string, params?: any): string {
    if (typeof params !== "object") {
      params = {};
    }

    params.type = this.type;
    params.api_token = this.apiToken;
    params.client_id = Env.keycloak.clientId();

    return URI(this.baseUl + "/" + path).addQuery(params).normalize().toString();
  }

  /**
   * Create a listener to Window to check callback return.
   *
   * @param {Window}   source
   * @param {Function} callback
   */
  private addListenerForPostmessage(source: Window, callback?: Function) {
    (window as Window).addEventListener("message", (event) => {
      const data = event.data;

      if (event.source !== source || !data.access_token) {
        return;
      }

      if (data.profile) {
        this.userinfo = Object.assign(data, { profile: new User(data.profile) });
      }

      Session.setTokens(data);

      if (typeof callback !== "function") {
        return;
      }

      callback(this.userinfo);
    });
  }

  private getRediretUrl(url: string): string {
    const currentUrl = (window as Window).location.href;
    return url ? url : currentUrl;
  }
}
