import Component from "components/component";
import Frontend from "helpers/frontend";
import InstallmentSelectEvent from "events/installment-select-event";
import Observable from "observers/observable";
import Env from "environment/env";
import Pattern from "helpers/pattern";
import NumericMaskedInput from "components/numeric-masked-input";

export default class Form extends Observable<InstallmentSelectEvent> implements Component {
  public async build(): Promise<HTMLElement> {
    return Frontend.div({}, [
      Frontend.div({ class: "form-fields" }, [
        await this.creditCardFields(),
      ]),
    ]);
  }

  private async creditCardFields(): Promise<HTMLElement> {
    const number = new NumericMaskedInput({
      name: "cc_number",
      "data-format": "****.****.****.****",
      autocomplete: "cc-number",
      pattern: Pattern.creditCard(),
      placeholder: "Número do cartão",
      parentClass: "input-container",
      required: true,
    });

    const holder = Frontend.input({
      name: "cc_holder",
      pattern: Pattern.ccName(),
      required: true,
      autocomplete: "cc-name",
      placeholder: "Nome impresso no cartão",
    });

    const securityCode = new NumericMaskedInput({
      name: "cc_security_code",
      pattern: Pattern.ccCsc(),
      required: true,
      autocomplete: "cc-csc",
      placeholder: "Código de segurança",
      parentClass: "input-container",
      "data-format": "***",
    });

    return Frontend.div({ id: "credit-card" }, [
      Frontend.div({ class: "input-container" }, [
        await this.buildAndApplyComponentStyle(number),
        Frontend.img({}, `${Env.images.baseUrl()}images/credit-card-alt.png`)
      ]),
      Frontend.div({ class: "input-container" }, [
        holder,
      ]),
      await this.dueDateFields(),
      Frontend.div({ class: "input-container" }, [
        await this.buildAndApplyComponentStyle(securityCode),
      ]),
      this.dropdownInstallments(),
    ]);
  }

  private dropdownInstallments(): HTMLElement {
    const selectElement = Frontend.select({ name: "installments", id: "installments", required: true, class: "default" }, [
      Frontend.option({ value: "", disabled: "disabled", selected: "selected" }, "Parcelas"),
    ]) as HTMLSelectElement;

    const handler = this;

    selectElement.addEventListener("change", () => {
      selectElement.classList.remove('default');
      handler.notify({ installment: Number(selectElement.value) });
    });

    return Frontend.div({ class: "select-container" }, [
      selectElement
    ]);
  }

  private async dueDateFields(): Promise<HTMLElement> {
    const month = new NumericMaskedInput({
      name: "month",
      pattern: Pattern.ccExpirationMonth(),
      required: true,
      autocomplete: "cc-exp-month",
      placeholder: "MM",
      "data-format": "**",
      parentClass: "input-container",
    });

    const year = new NumericMaskedInput({
      name: "year",
      pattern: Pattern.ccExpirationYear(),
      required: true,
      autocomplete: "cc-exp-year",
      placeholder: "AAAA",
      "data-format": "****",
      parentClass: "input-container",
    });

    return Frontend.div({ class: "input-group" }, [
      Frontend.label({}, "Validade"),
      Frontend.div({}, [
        Frontend.div({ class: "input-container" }, [
          await this.buildAndApplyComponentStyle(month),
        ]),
        Frontend.div({ class: "input-container" }, [
          await this.buildAndApplyComponentStyle(year),
        ]),
      ])
    ]);
  }

  private async buildAndApplyComponentStyle(component: Component): Promise<HTMLElement> {
    const element = await component.build();
    Frontend.makeStyle(component.style());
    return element;
  }

  style(): string {
    return `
      #${Env.iframeElement()} .select-container {
        border: 2px solid rgba(203,203,203, 0.5);
        border-radius: 999px;

        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        margin-top: 2em;
      }

      #${Env.iframeElement()} .select-container > select {
        width: 95%;
        background: transparent;
        padding: 1em;
        text-indent: 0.01px;
        outline: none;
        border: none;
        cursor: pointer;
      }

      #${Env.iframeElement()} .select-container > select.default,
      #${Env.iframeElement()} .select-container > select option:disabled {
        color: #a4a4a4;
      }

      #${Env.iframeElement()} .select-container > select option {
        color: #333;
      }

      #${Env.iframeElement()} .input-container {
        position: relative;
        border: 2px solid rgba(203,203,203, 0.5);
        border-radius: 999px;
        overflow: hidden;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      #${Env.iframeElement()} .input-container:not(:first-of-type) {
        margin-top: 2em;
      }

      #${Env.iframeElement()} .input-container img {
        padding: 0 2em;
        height: 25px;
      }

      #${Env.iframeElement()} .input-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      #${Env.iframeElement()} .input-group label {
        padding: 1.2em 2em;
        color: #999999;
      }

      #${Env.iframeElement()} .input-group>div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      #${Env.iframeElement()} .input-group .input-container {
        margin-top: 0;
      }

      #${Env.iframeElement()} .input-group .input-container:last-of-type {
        margin-left: 2em;
      }
      @media (min-width: 768px) {
        #${Env.iframeElement()} .input-group {
          margin-top: 2em;
          flex-wrap: nowrap;
        }
      }`;
  }
}
