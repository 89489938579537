import Observer from "./observer";

export default class Observable<TEvent> {
  protected observers: Observer<TEvent>[];

  public constructor() {
    this.observers = [];
  }

  public async notify(event: TEvent): Promise<void> {
    for (const observer of this.observers) {
      await observer.handle(event);
    }
  }

  public subscribe(observer: Observer<TEvent>): void {
    this.observers.push(observer);
  }
}
