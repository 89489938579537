import { LoginConfig } from "concerns/login-config";
import { LoginStatus } from "concerns/login-status";
import LoginStatusStatus from "concerns/login-status-status";
import { PaywallConfig } from "concerns/paywall-config";
import { UserinfoResponse } from "concerns/userinfo-response";
import SignInWall from "facades/sign-in-wall";
import SignInWallInitializer from "facades/sign-in-wall-initializer";
import Logger from "helpers/logger";
import Session from "helpers/session";
import ApiAuth from "integrations/api-auth";
import SignInWallApi from "integrations/sign-in-wall-api";
import SubscribeApi from "integrations/subscribe-api";
import { SubscribeAPIResponse } from "concerns/subscribe-api-response";
import { Campaign } from "concerns/campaign";
import { SubscribeApiPayment } from "concerns/subscribe-api-payment";
import Env from "environment/env";

export default class Main {

  public static readonly allowedTypes = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  public static SignInWall: SignInWallInitializer;
  private static authApi: ApiAuth;
  private static signInWallApi: SignInWallApi;
  private static subscribeApi: SubscribeApi;
  private static config: LoginConfig;
  private static signInWall: SignInWall;
  private static initialized: boolean = false;

  /**
   * Initialize the SDK
   */
  public static async init(config: LoginConfig) {
    Logger.debug("Initializing");

    if (this.initialized) {
      Logger.error("SJCC_Login já foi inicializada.");
      return;
    }

    this.config = this.normalizeLoginConfig(config);
    this.validateLoginConfig();
    this.provideServices();
  }

  /**
   * creates an instance and assigns to private services declared on class attributes
   */
  private static provideServices(): void {
    this.authApi = new ApiAuth(Env.backend.apiUrl(), this.config.apiToken, this.config.type);
    this.subscribeApi = new SubscribeApi(Env.backend.apiUrl(), this.config.apiToken);
    this.signInWallApi = new SignInWallApi(Env.backend.apiUrl());
    this.signInWall = new SignInWall(this.signInWallApi, this.config.tracking);
    this.SignInWall = new SignInWallInitializer(this.signInWall);
  }

  /**
   * Retrieve the status of login.
   * Will check session and request to API/userinfo
   */
  public static async getLoginStatus(callback: Function, forceLoad?: boolean) {
    if (! this.authApi) {
      return;
    }

    let userinfo: UserinfoResponse = await Main.getUserInfoFromApiAuth(forceLoad);

    if (! Main.userLoggedIn(userinfo)) {
      userinfo = await Main.getUserInfoFomSSO(userinfo);
    }

    if (! Main.userLoggedIn(userinfo)) {
      Session.forgetTokens();
      callback({ status: LoginStatusStatus.NOT_CONNECTED });
      return;
    }

    const loginStatus: LoginStatus = Main.userInfoToLoginStatus(userinfo);
    callback(loginStatus);
  }

  private static async getUserInfoFomSSO(userinfo: UserinfoResponse): Promise<UserinfoResponse> {
    await this.authApi.checkSso();
    return await this.authApi.getUserInfo();
  }

  private static userLoggedIn(userinfo: UserinfoResponse) {
    return userinfo && userinfo.access_token !== null;
  }

  private static async getUserInfoFromApiAuth(forceLoad?: boolean): Promise<UserinfoResponse> {
    await this.authApi.buildFromUrl();
    return await this.authApi.getUserInfo(forceLoad) as UserinfoResponse;
  }

  private static userInfoToLoginStatus(userinfo: UserinfoResponse) {
    const profile: any = userinfo.profile;
    profile.userdata = userinfo.userdata;

    const loginStatus: LoginStatus = {
      accessToken: userinfo.access_token,
      refreshToken: userinfo.refresh_token,
      status: LoginStatusStatus.CONNECTED,
      userinfo: profile,
    };

    return loginStatus;
  }

  private static normalizeLoginConfig(config: LoginConfig): LoginConfig {
    config.type = (config.type || "1").toString();
    if (this.allowedTypes.indexOf(config.type) < 0) {
      config.type = "1";
    }

    config.popup = config.popup || false;
    config.paywall = (config.paywall || null) as PaywallConfig;

    if (!config.tracking || !config.tracking.appId) {
      Logger.debug("O parâmetro 'appId' não foi informado: o tracking deve ser implementado manualmente.");
      this.config.tracking = null;
    }
    return config;
  }

  private static validateLoginConfig(): boolean {
    if (!this.config.apiToken) {
      Logger.error("O parâmetro 'apiToken' é obrigatório.");
      return false;
    }

    if (this.config.paywall &&
      (!this.config.paywall.text || !this.config.paywall.image || !this.config.paywall.url)) {
      Logger.error("O paywall não foi configurado corretamente.");
      return false;
    }
    return true;
  }

  public static getConfig() {
    return this.config;
  }

  /**
   * Public middleware for ApiAuth.login
   */
  public static login(callback?: Function) {
    if (this.config.popup) {
      this.authApi.loginFromPopup(callback);
      return;
    }

    this.authApi.login();
  }

  /**
   * Public middleware for ApiAuth.loginFromPopup
   */
  public static loginWithPopup(callback?: Function) {
    this.authApi.loginFromPopup(callback);
  }

  /**
   * Public middleware for ApiAuth.logout
   */
  public static logout(redirectUrl?: string) {
    this.authApi.logout(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.reauthenticate
   */
  public static reauthenticate(redirectUrl?: string) {
    this.authApi.reauthenticate(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.logoutTokens
   */
  public static async logoutTokens() {
    return this.authApi.logoutTokens();
  }

  /**
   * Public middleware for ApiAuth.getLoginUrl
   */
  public static getLoginUrl(redirectUrl?: string): string {
    return this.authApi.getLoginUrl(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.getRegisterUrl
   */
  public static getRegisterUrl(redirectUrl?: string): string {
    return this.authApi.getRegisterUrl(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.getAccountUrl
   */
  public static getAccountUrl(redirectUrl?: string): string {
    return this.authApi.getAccountUrl(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.getAccountResourceUrl
   */
  public static getAccountResourceUrl(resource: string): string {
    return this.authApi.getAccountResourceUrl(resource);
  }

  /**
   * Public middleware for ApiAuth.getLogoutUrl
   */
  public static getLogoutUrl(redirectUrl?: string): string {
    return this.authApi.getLogoutUrl(redirectUrl);
  }

  /**
   * Public middleware for ApiAuth.getReauthenticateUrl
   */
  public static getReauthenticateUrl(redirectUrl?: string): string {
    return this.authApi.getReauthenticateUrl(redirectUrl);
  }

  /**
   * Public middleware for SignInWall.tracking
   */
  public static tracking() {
    this.signInWall.tracking();
  }

  /**
   * Public middleware for Subscribe Api.getCampaign
   */
  public static getSubscribeApi(): SubscribeApi {
    return this.subscribeApi;
  }
}
