import { LoginStatus } from "concerns/login-status";
import CheckRuleResponseAction from "concerns/check-rule-response-action";
import { CheckRuleResponse } from "concerns/check-rule-response";
import WallAttributes from "./attributes/wall-attributes";
import SignInWall from "./sign-in-wall";
import PayWall from "./pay-wall";
import SignInWallAttributes from "./attributes/signinwall-attributes";
import TaximeterAttributes from "./attributes/taximeter-attributes";
import PaywallAttributes from "./attributes/paywall-attributes";
import DisconnectedTaximeter from "./disconnected-taximeter";
import Wall from "./wall";
import LoginStatusStatus from "concerns/login-status-status";
import ConnectedTaximeter from "./connected-taximeter";

export default class WallFactory {

  public constructor(protected wrapperElement: HTMLElement, protected loginStatus: LoginStatus, protected trackingMetadata: any) { }

  public fromCheckRuleResponse(checkRuleResponse: CheckRuleResponse) {
    switch (checkRuleResponse.action) {
      case CheckRuleResponseAction.SKIP: return null;
      case CheckRuleResponseAction.BLOCK: return this.createBlockLoader(checkRuleResponse);
      case CheckRuleResponseAction.TAXIMETER: return this.createTaximeterLoader(checkRuleResponse);
      case CheckRuleResponseAction.PAYWALL: return this.createPaywallLoader(checkRuleResponse);
    }
  }

  private createBlockLoader(response: CheckRuleResponse): Wall {
    const wallAttributes = this.attributesOrDefault(response);
    const attributes = this.buildSignInWallAttributes(wallAttributes);

    return new SignInWall(this.wrapperElement, this.loginStatus, attributes);
  }

  private createPaywallLoader(response: CheckRuleResponse): Wall {
    const wallAttributes = this.attributesOrDefault(response);
    const attributes = this.buildPaywallAttributes(wallAttributes);

    return new PayWall(this.wrapperElement, this.loginStatus, attributes);
  }

  private createTaximeterLoader(response: CheckRuleResponse): Wall {
    const wallAttributes = this.attributesOrDefault(response);
    const remaining = this.remainingVisits(response);
    const attributes = this.buildTaximeterAttributes(wallAttributes, remaining);

    if (this.isLoggedIn()) {
      return new ConnectedTaximeter(this.wrapperElement, this.loginStatus, attributes);
    }

    return new DisconnectedTaximeter(this.wrapperElement, this.loginStatus, attributes);
  }

  private buildSignInWallAttributes(wallAttributes: WallAttributes): SignInWallAttributes {
    return wallAttributes as SignInWallAttributes;
  }

  private buildTaximeterAttributes(wallAttributes: WallAttributes, remaining: number): TaximeterAttributes {
    const attributes: TaximeterAttributes = {
      paywall: this.buildPaywallAttributes(wallAttributes),
      remaining,
    };

    return attributes;
  }

  private buildPaywallAttributes(wallAttributes: WallAttributes): PaywallAttributes {
    const attributes: PaywallAttributes = wallAttributes as PaywallAttributes;

    attributes.tracking_metadata = this.trackingMetadata;

    return attributes;
  }

  private isLoggedIn(): boolean {
    return this.loginStatus.status !== LoginStatusStatus.NOT_CONNECTED;
  }

  private attributesOrDefault(response: CheckRuleResponse): WallAttributes {
    return response.metadata || [];
  }

  private remainingVisits(response: CheckRuleResponse): number {
    return (response.data.limit || 0) - (response.data.visits || 0);
  }
}
