import PaymentButton from 'payment/interface/payment-button';
import Observer from 'observers/observer';
import PlanSelectEvent from 'events/plan-select-event';
import LoadedEWalletEvent from "events/loaded-ewallet-event";
import PaymentFeedbackEvent from "events/payment-feedback-event";
import Frontend from 'helpers/frontend';
import ApplePaySessionProvider from './providers/apple-pay-session-provider';

export default class ApplePay extends PaymentButton implements Observer<PlanSelectEvent>, Observer<PaymentFeedbackEvent>  {
  private lineTotal: ApplePayJS.ApplePayLineItem;
  private plan_title: string;
  private variation_id: number;
  private provider: ApplePaySessionProvider;

  public constructor(protected isActive: boolean) {
    super();
  }

  public async allowed(): Promise<boolean> {
    return this.isActive && (<any>window).ApplePaySession && ApplePaySession.canMakePayments();
  }

  public async createButton(): Promise<void> {
    if (! await this.allowed()) {
      return;
    }

    const element = document.getElementById('ewallets-buttons');
    element.appendChild(await this.build());
    Frontend.makeStyle(this.style());

    this.notify({wallet : 'ApplePay'} as LoadedEWalletEvent);
  }

  public async build(): Promise<HTMLElement> {
    const button = Frontend.div({
      id: 'ckoApplePay',
      class: 'apple-pay-button',
      lang: 'pt'
    });

    button.addEventListener('click', async () => {
      await this.onClick();
    });

    return Frontend.div({class: 'apple-pay-button-wrapper'}, [Frontend.div({class: 'apple-pay-button-background'}, [button])]);
  }

  private async onClick() {
    if (! this.validatePersonalData()) {
      return;
    }

    this.provider = new ApplePaySessionProvider(this.plan_title, this.variation_id, this.lineTotal, (event: any) => this.notify(event));
    await this.provider.initialize();
  }

  public async handle(event: PlanSelectEvent | PaymentFeedbackEvent): Promise<void> {
    if ((event as PlanSelectEvent).button !== undefined) {
      return this.onPlanSelect(event as PlanSelectEvent);
    }

    if ((event as PaymentFeedbackEvent).payment_status !== undefined) {
      return this.onPaymentComplete(event as PaymentFeedbackEvent);
    }
  }

  private async onPlanSelect(event: PlanSelectEvent): Promise<void> {
    this.plan_title = event.campaign.name;
    this.variation_id = event.campaign.id;

    this.lineTotal = {
      label: event.campaign.name,
      amount: event.campaign.price.toString(),
      type: 'final'
    }
  }

  private async onPaymentComplete(event: PaymentFeedbackEvent): Promise<void> {
    if (! this.provider) {
      return;
    }

    const success: boolean = (event.payment_status !== 'success');
    const errors: Array<ApplePayJS.ApplePayError> = [];

    for (var i = 0; i < event.errors.length; i++) {
      const error: ApplePayJS.ApplePayError = {
        code: 'unknown',
        message: event.errors[i]
      };

      errors.push(error);
    }

    this.provider.completePayment(success, errors);
  }

  public style(): string {
    return `
      .apple-pay-button-wrapper {
        width: 100%;
        display: block;
      }
      .apple-pay-button-background {
        max-width: 250px !important;
        background: #000000;
        margin: 0 auto;
        border-radius: 4px;
        padding: 4px 0;
        box-sizing: border-box;
        height: 40px;
      }
      .apple-pay-button {
        width: 100% !important;
        max-width: 230px !important;
        height: 32px;
        display: inline-block;
        cursor: pointer;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: subscribe;
        -apple-pay-button-style: black;
      }`;
  }
}
