import Component from "components/component";
import Frontend from "helpers/frontend";
import Env from "environment/env";

export default class Footer implements Component {

  public constructor(private backgroundColor: string) { }

  public async build(): Promise<HTMLElement> {
    const footer = Frontend.footer({}, [
      Frontend.p({}, 'Dúvidas? <a target=\'_blank\' href=\'https://api.whatsapp.com/send?phone=558134136100&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20op%C3%A7%C3%B5es%20de%20assinatura%20digital%20do%20JC\'>Fale Conosco</a>'),
    ]);

    return footer;
  }

  style(): string {
    return `
      #${Env.iframeElement()} footer {
        background: ${this.backgroundColor};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5em 0;
      }

      #${Env.iframeElement()} footer p {
        margin: 0;
      }

      #${Env.iframeElement()} footer p > a {
        text-decoration: none;
        font-weight: bold;
        color: #000;
      }`
  }
}
