import Env from 'environment/env';

export default abstract class BaseRequestBuilder {
  protected getAllowedPaymentMethods(): google.payments.api.PaymentMethodSpecification[] {
    return [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['VISA', 'MASTERCARD'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            'gateway': Env.gpay.gateway(),
            'gatewayMerchantId': Env.gpay.gatewayMerchantId(),
          }
        }
      },
    ];
  }
}
