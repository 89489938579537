import Component from "components/component";
import Frontend from "helpers/frontend";
import Env from "environment/env";

export default class OrderResume implements Component {

  public async build(): Promise<HTMLElement> {
    return Frontend.div({ class: "order-resume" }, [
      Frontend.header({}, [
        Frontend.p({ class: "active-line" }, "Resumo do pedido"),
      ]),
      Frontend.div({ class: "card" }, [
        Frontend.div({ id: "resume-title" }, [
          Frontend.span({}, "JC Digital"),
          Frontend.span({}, "R$ 00,00 / mês"),
        ]),
        Frontend.div({}, [
          Frontend.span({}, "Parcelas"),
          Frontend.input({ id: "resume-total-value", type: "hidden" }),
          Frontend.span({ id: "resume-installments" }, "12x R$00,00 s/ juros"),
        ]),
        Frontend.div({}, [
          Frontend.span({}, "Pagamento"),
          Frontend.span({}, "Crédito"),
        ]),
        Frontend.div({}, [
          Frontend.span({}, "Total"),
          Frontend.span({ id: "resume-total" }, "R$ 0000,00"),
        ]),
      ]),
    ]);
  }

  style(): string {
    return `
      #${Env.iframeElement()} .order-resume {
        margin-bottom: 3em;
      }

      #${Env.iframeElement()} .card {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        margin-top: 2em;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5em;
      }

      #${Env.iframeElement()} .card div {
        width: 100%;
        padding: 1.5em;
        color: #a4a4a4;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      #${Env.iframeElement()} .card div:first-of-type,
      #${Env.iframeElement()} .card div:last-of-type {
        font-weight: bold;
        color: #484848;
    }`;
  }
}
