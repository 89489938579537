import * as csso from "csso";

import Main from "facades/main";
import Device from "helpers/device";

export default class Frontend {
  /**
   * Create a DIV
   */
  public static div(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("div", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a P
   */
  public static p(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("p", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a BR
   */
  public static br(properties: any = {}): HTMLElement {
    const element = this.create("br", properties);
    return this.addChildren(element);
  }

  /**
   * Create an A
   */
  public static a(properties: any = {}, link: string = "#", content: any = ""): HTMLElement {
    const element = this.create("a", properties);
    (element as HTMLLinkElement).href = link;

    // It's a fix for some links on mobile devices
    if (properties && ! properties.onclick) {
      (element as HTMLLinkElement).onclick = () => {
        if (link === "#") {
          return false;
        }

        const target = properties.target || "";
        if (target === "_blank") {
          return true;
        }

        (window as Window).location.href = link;
      };
    }

    return this.addChildren(element, content);
  }

  /**
   * Create an IMG
   */
  public static img(properties: any = {}, link: string = "#", alt: string = ""): HTMLElement {
    const element = this.create("img", properties);
    (element as HTMLImageElement).src = link;
    (element as HTMLImageElement).alt = alt;

    return this.addChildren(element);
  }

  /**
   * Create a UL
   */
  public static ul(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("ul", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a LI
   */
  public static li(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("li", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a HEADER
   */
  public static header(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("header", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a SPAN
   */
  public static span(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("span", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a BUTTON
   */
  public static button(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("button", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a FOOTER
   */
  public static footer(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("footer", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a FORM
   */
  public static form(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("form", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a INPUT
   */
  public static input(properties: any = {}): HTMLElement {

    if (!properties.type) {
      properties.type = 'text'
    }

    return this.create("input", properties);
  }

  /**
   * Create a SPAN
   */
  public static label(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("label", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a SELECT
   */
  public static select(properties: any = {}, children?: Array<HTMLElement | any>): HTMLElement {
    const element = this.create("select", properties);
    return this.addChildren(element, children);
  }

  /**
   * Create a OPTION
   */
  public static option(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("option", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a H4
   */
  public static h4(properties: any = {}, innerHtml: any = ""): HTMLElement {
    const element = this.create("h4", properties);
    return this.addChildren(element, innerHtml);
  }

  /**
   * Create a STYLE
   */
  public static makeStyle(style: any, media?: any): void {
    if (! style) {
      return;
    }

    const styleElement = document.createElement("style");
    styleElement.setAttribute("type", "text/css");

    if (media) {
      styleElement.setAttribute("media", media);
    }

    styleElement.innerHTML = csso.minify(style).css;

    document.body.appendChild(styleElement);
  }

  /**
   * Mobile style
   *
   * If it's forced mobile, will add as regular style.
   * If not, use config mobile (media query)
   */
  public static makeMobileStyle(style: any): void {
    const config = Main.getConfig().mobile || "(max-width: 768px)";
    const media = (Device.isMobile()) ? "" : config;

    this.makeStyle(style, media);
  }

  /**
   * Create a LINK for stylesheet
   */
  public static stylesheet(href: string): HTMLStyleElement {
    const element = document.createElement("link");
    element.href = href;
    element.setAttribute("type", "stylesheet");

    document.head.appendChild(element);

    return element;
  }

  /**
   * Create a SCRIPT tag for a JS
   */
  public static script(src: string, properties?: any): HTMLScriptElement {
    const element = this.create('script', properties) as HTMLScriptElement;
    element.src = src;

    document.head.appendChild(element);

    return element;
  }

  /**
   * Create a element
   */
  public static create(tag: string, properties?: any): HTMLElement {
    const element = document.createElement(tag);
    if (! properties) {
      properties = {};
    }

    if (properties.class) {
      if (typeof properties.class !== "object") {
        properties.class = [properties.class];
      }

      for (const property of properties.class) {
        property.split(' ').forEach((className: string) => {
          element.classList.add(className);
        });
      }
    }

    if (properties.onload) {
      element.onload = properties.onload;
    }

    if (properties.onclick) {
      element.onclick = properties.onclick;
    }

    for (const property of Object.keys(properties)) {
      if (property === 'class' || property === 'onload' || property === 'onclick') {
        continue;
      }

      const value = properties[property];
      if (typeof value === 'undefined' || value === null) {
        continue;
      }

      element.setAttribute(property, value);
    }

    return element;
  }

  /**
   * Add a element (or array of them) as a child
   */
  public static addChildren(element: HTMLElement, children?: Array<HTMLElement | any> | string): HTMLElement {
    if (!children) {
      return element;
    }

    if (typeof children === "string") {
      element.innerHTML = children;
      return element;
    }

    if (!Array.isArray(children)) {
      children = [children];
    }

    for (const child of children) {
      if (!child) {
        continue;
      }

      if (typeof child === "string") {
        element.innerHTML += child;
        continue;
      }

      element.appendChild(child);
    }

    return element;
  }
}
