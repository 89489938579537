import Logger from "./logger";

export default class Analytics {
  constructor(private tracking_metadata: any) { }

  public send<T>(data: T): void {
    const visit = {
      metadata: Object.assign({}, this.tracking_metadata, data),
    };

    const tracking = (window as any).Tracker || null;
    if (! tracking) {
      Logger.debug('Tracking inválido: não pudemos enviar os dados de status.');
      return;
    }

    Logger.debug('[Tracking]', visit.metadata);
    tracking.trackVisit(visit);
  }
}
