/**
 * Creating configs?
 *
 * Do not forget to add to other files in /config
 */
export default class Config {
  public static ENVIRONMENT = "production";

  // Backend API
  public static BACKEND_API_URL = "https://signin-wall.accounts.ne10.com.br/api/v2";

  // IMAGES URL
  public static IMAGE_BASE_URL = "https://s3.amazonaws.com/sdk-signin-wall-production/";

  // Tracking URL
  public static TRACKING_BASE_URL = "https://apps.ne10.uol.com.br";
  public static TRACKING_JS_URL = Config.TRACKING_BASE_URL + "/api/v1/tracking.js";

  // ID for signinwall/taximeter wrapper
  public static SIGNIN_WALL_IFRAME_ELEMENT = "signinwall-iframe";
  public static TAXIMETER_ELEMENT_ID = "signin-wall-taximiter";

  // Keycloak Config
  public static KEYCLOAK_BASE_URL = "https://keycloak-production.accounts.ne10.com.br/auth";
  public static KEYCLOAK_REALM = "sjcc";
  public static KEYCLOAK_CLIENT_ID = "api-auth";

  // Assine Config
  public static SUBSCRIBE_API_URL = "https://front.dev.sjcc.com.br/assinejc/api/v1/";
  public static SUBSCRIBE_API_TOKEN = "23b145cb7211c11d82e9b850d800527e3e2d3b57";

  // GPay
  public static GPAY_ENVIRONMENT = "PRODUCTION";
  public static GPAY_MERCHANT_NAME = "Assine JC";
  public static GPAY_MERCHANT_ID = "";
  public static GPAY_GATEWAY = "cielo";
  public static GPAY_GATEWAY_MERCHANT_ID = "754F674D-F763-43B3-AEB8-4FCBE33FAEE7";
}
