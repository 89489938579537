import Frontend from "helpers/frontend";
import Env from "environment/env";
import Component from "./component";

export default class NumericMaskedInput implements Component {

  public constructor(protected data: {
    id?: string,
    name: string,
    "data-format": string,
    autocomplete: string,
    pattern: string,
    placeholder: string,
    parentClass: string,
    required: boolean
  }) { }

  public async build(): Promise<HTMLElement> {
    const input = Frontend.input({
      id: this.data.id || null,
      name: this.data.name,
      "data-format": this.data["data-format"],
      autocomplete: this.data.autocomplete,
      pattern: this.data.pattern,
      placeholder: this.data.placeholder,
      required: this.data.required,
    }) as HTMLInputElement;

    const instance = this;

    input.addEventListener('keyup', function () {
      instance.format(input as HTMLInputElement);
    });

    input.addEventListener('change', function () {
      instance.format(input as HTMLInputElement);
    });

    return input;
  }

  private format(input: HTMLInputElement) {
    const mask = this.getMask(input);
    const rawValue = this.normalize(input.value, mask);
    input.value = this.normalize(input.value, mask);
  }

  private getMask(input: HTMLInputElement): string {
    const inputName = input.getAttribute('name');
    if (inputName === 'telephone') {
      return this.phoneMask(input);
    }

    return input.getAttribute('data-format');
  }

  private phoneMask(input: HTMLInputElement): string {
    const value = this.removeNonNumbers(input.value);
    if (value.length <= 10) {
      return "(**) ****-****";
    }

    return "(**) *****-****";
  }

  private normalize(value: string, pattern: string) {
    const numericValue = this.removeNonNumbers(value);
    let valueIndex = 0;
    let normalizedValue = '';

    for (const expectedFormat of pattern) {
      if (! numericValue[valueIndex]) {
        return normalizedValue;
      }

      if (this.wildCard(expectedFormat)) {
        normalizedValue += numericValue[valueIndex];
        valueIndex++;
      } else {
        normalizedValue += expectedFormat;
      }
    }

    return normalizedValue;
  }

  private wildCard(expectedFormat: string) {
    return /\*/.test(expectedFormat);
  }

  private removeNonNumbers(value: string) {
    return value.replace(/[^0-9]/g, "");
  }

  style(): string {
    return `
    #${Env.iframeElement()} .${this.data.parentClass} input {
      background: transparent;
      border: none;
      padding: 1em;
      color: #333333;
      width: 100%;
    }

    #${Env.iframeElement()} .${this.data.parentClass} input:focus,
    .${this.data.parentClass} input:hover {
      outline: none;
    }

    #${Env.iframeElement()} .${this.data.parentClass} input::placeholder {
      color: #a4a4a4;
    }`;
  }
}
