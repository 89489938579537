import Button from "components/button";
import { PaymentEvent } from "events/payment-event";
import LoadedEWalletEvent from "events/loaded-ewallet-event";
import PaymentFeedbackEvent from "events/payment-feedback-event";

export default abstract class PaymentButton extends Button<PaymentEvent|LoadedEWalletEvent> {
  abstract allowed(): Promise<boolean>;
  abstract createButton(): Promise<void>;

  protected validatePersonalData(): boolean {
    const cpfInput = document.getElementById('user-data-cpf') as HTMLInputElement;
    const phoneInput = document.getElementById('user-data-telephone') as HTMLInputElement;

    let valid = true;

    if (cpfInput && ! cpfInput.checkValidity()) {
      valid = false;
      cpfInput.reportValidity();
    }

    if (phoneInput && ! phoneInput.checkValidity()) {
      valid = false;
      phoneInput.reportValidity();
    }

    return valid;
  }
}
