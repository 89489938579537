import Component from "components/component";
import Frontend from "helpers/frontend";
import { Campaign } from "concerns/campaign";
import Format from "helpers/format";
import UnselectedButton from "./unselected-button";
import PlanSelectEvent from "events/plan-select-event";
import Observable from "observers/observable";
import Env from "environment/env";

export default class PlanCard extends Observable<PlanSelectEvent> implements Component {
  private campaign: Campaign;
  private isPopular: boolean;

  public constructor(campaign: Campaign, isPopular: boolean) {
    super();
    this.campaign = campaign;
    this.isPopular = isPopular;
  }

  public async build(): Promise<HTMLElement> {
    const campaign = this.campaign;

    let mostPopularFlag = null;
    if (this.isMostPopularPlan()) {
      mostPopularFlag = Frontend.span({}, "mais popular");
    }

    const cardPlan = Frontend.div({ class: "cards-plans" }, [
      mostPopularFlag,
      Frontend.p({ class: "title" }, campaign.campaign),
      Frontend.img({}, `${campaign.paywall_image}`),
      Frontend.div({ class: "price" }, [
        Frontend.span({}, "Por apenas"),
        Frontend.p({}, `R$ <strong>${Format.price(campaign.price)}</strong> / ${campaign.subscription_type}`),
      ]),
      Frontend.div({ class: "description" }, [
        Frontend.p({}, campaign.paywall_description),
        await this.buildPlanSelectButton(campaign)
      ]),
      Frontend.div({ class: "plan-card-footer" }, [
        Frontend.button({ class: "accordion" }, [
          Frontend.span({}, "Mais informações "),
          Frontend.img({}, `${Env.images.baseUrl()}images/icon-arrow-down.png`)
        ]),
        Frontend.ul({}, [
          ...this.buildMoreInfoToogle(campaign.paywall_features),
        ])
      ])
    ]);

    return cardPlan;
  }

  private isMostPopularPlan(): boolean {
    return this.isPopular;
  }

  /**
   * returns a button to select a plan that notify subscribers on click
   * @param campaign : campaign data fetched from subscribe API
   */
  private async buildPlanSelectButton(campaign: Campaign) {
    const button = new UnselectedButton<PlanSelectEvent>({ id: "btn-plan-" + campaign.id, text: "Selecionar", class: "btn-plan" });
    Frontend.makeStyle(button.style());

    const buttonElement = await button.build();

    const handler = this;
    buttonElement.addEventListener("click", function () {
      handler.notify({ button: buttonElement, campaign: campaign });
    });

    return buttonElement;
  }

  private buildMoreInfoToogle(features: string[]): HTMLElement[] {
    let featuresPlans = [];
    if (features.length > 0) {
      for (const feature of features) {
        featuresPlans.push(
          Frontend.li({}, `- ${feature}`),
        );
      }
      return featuresPlans;
    }

    return [Frontend.li({}, '- Sem mais informações')];
  }


  style(): string {
    return `
      #${Env.iframeElement()} .cards-wrapper {
        padding: 0 1.8em 2em;
      }

      #${Env.iframeElement()} .cards-plans {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.12);
        margin-top: 2em;

        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1.5em;
      }

      #${Env.iframeElement()} .cards-plans > img {
        margin: 2em 0;
      }

      @media (min-width: 768.1px) {
        #${Env.iframeElement()} .cards-wrapper {
          display: flex;
          align-items: stretch;
          justify-content: center;
          margin-top: 2em;
        }

        #${Env.iframeElement()} .cards-plans {
          margin-top: 0;
          flex: 0 1 100%;
        }

        #${Env.iframeElement()} .cards-plans:nth-of-type(1) {
          order: 2;
          margin-left: 1em;
        }

        #${Env.iframeElement()} .cards-plans:nth-of-type(2) {
          order: 1;
          margin-left: 1em;
        }

        #${Env.iframeElement()} .cards-plans:nth-of-type(3) {
          order: 3;
        }

        #${Env.iframeElement()} .cards-plans:not(:first-of-type) {
          margin-left: 1em;
          margin-top: 0;
        }
      }

      #${Env.iframeElement()} .cards-plans>span {
        background-color: #fb0021;
        padding: 0 0.8em;
        border-radius: 999px;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 1.8;
        font-weight: bold;
        position: absolute;
        top: -8px;
        margin: 0 auto;
      }

      #${Env.iframeElement()} .cards-plans .title {
        font-weight: 900;
        display: block;
        padding-top: 2em;
        margin-bottom: 0;
      }

      #${Env.iframeElement()} .cards-plans .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      #${Env.iframeElement()} .cards-plans .price p {
        font-size: 16px;
        margin: 0.5em 0 0;
      }

      #${Env.iframeElement()} .cards-plans .price p strong {
        color: #fb0021;
        font-size: 32px;
      }

      #${Env.iframeElement()} .cards-plans .description {
        text-align: center;
        line-height: 1.5;
        padding: 0 1.5em 1em;
      }

      #${Env.iframeElement()} .cards-plans .description p {
        margin-bottom: 2em;
      }

      #${Env.iframeElement()} .cards-plans .plan-card-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      #${Env.iframeElement()} .cards-plans .plan-card-footer button {
        background: transparent;
        border: none;
        padding: 0.5em 0 1em;
        cursor: pointer;
        outline: none;
      }

      #${Env.iframeElement()} .cards-plans .plan-card-footer i {
        font-style: unset;
      }

      #${Env.iframeElement()} .cards-plans .plan-card-footer>ul {
        margin: 0;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid #f1f1f1;
        padding-top: 1em;
        display: none;
        overflow: hidden;
        text-align: left;
        transition: max-height 0.2s ease-out;
      }

      #${Env.iframeElement()} .cards-plans .plan-card-footer>ul li {
        padding: 0 1em 1em;
      }`
  }
}
