import * as URI from "urijs";

export default class Browser {
  public static hasParam(param: string, value: string): boolean {
    const currentUrl = (window as Window).location.href;
    const uri = URI(currentUrl);

    return uri.hasQuery(param, value);
  }

  public static removeParams(params: Array<string>): void {
    const currentUrl = (window as Window).location.href;
    const newUrl = URI(currentUrl).removeQuery(params);

    if (! newUrl || newUrl.equals(currentUrl)) {
      return;
    }

    const newState = window.history.state || {};
    const newTitle = document.title || "";

    (window as Window).history.replaceState(newState, newTitle, newUrl.toString());
  }

  public static removeParam(param: string): void {
    Browser.removeParams([param]);
  }
}
