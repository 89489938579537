import Env from "environment/env";

export default class Logger {
  public static debug(message: any, data?: any): void {
    if (Env.get() === "production") {
      return;
    }

    this.console('log', this.preffix(message), data);
  }

  public static log(message: string, data?: any): void {
    this.console('log', message, data);
  }

  public static info(message: string, data?: any): void {
    this.console('info', message, data);
  }

  public static error(message: string, data?: any): void {
    this.console('error', this.preffix(message, "error"), data);
  }

  private static console(method: "log" | "info" | "error", message: string, data?: any) {
    if (data) {
      console[method](message, data);
      return;
    }

    console[method](message);
  }

  private static preffix(message: any, preffix: string = "debug") {
    if (typeof message !== "string") {
      return message;
    }

    return "[" + preffix.toUpperCase() + "] " + message;
  }
}
