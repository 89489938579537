// this import is built when the docker image is up.
import Config from "config";

/**
 * OK, this class is not the best one. But before exist it,
 * the use of Conifg on all files was just boring taking error
 */
export default class Env {

  public static get(): string {
    return Config.ENVIRONMENT;
  }

  public static iframeElement(): string {
    return Config.SIGNIN_WALL_IFRAME_ELEMENT;
  }

  public static taximeterElementId(): string {
    return Config.TAXIMETER_ELEMENT_ID;
  }

  public static backend = {
    apiUrl(): string {
      return Config.BACKEND_API_URL;
    }
  }

  public static images = {
    baseUrl(): string {
      return Config.IMAGE_BASE_URL;
    }
  }

  public static tracking = {
    baseUrl(): string {
      return Config.TRACKING_BASE_URL;
    },
    jsUrl(): string {
      return Config.TRACKING_JS_URL;
    }
  }

  public static keycloak = {
    baseUrl(): string {
      return Config.KEYCLOAK_BASE_URL;
    },
    realm(): string {
      return Config.KEYCLOAK_REALM;
    },
    clientId(): string {
      return Config.KEYCLOAK_CLIENT_ID;
    }
  }

  public static subscribeApi = {
    url(): string {
      return Config.SUBSCRIBE_API_URL;
    },
    token(): string {
      return Config.SUBSCRIBE_API_TOKEN;
    }
  }

  public static gpay = {
    environment(): string {
      return Config.GPAY_ENVIRONMENT;
    },
    merchantName(): string {
      return Config.GPAY_MERCHANT_NAME;
    },
    merchantId(): string {
      return Config.GPAY_MERCHANT_ID;
    },
    gateway(): string {
      return Config.GPAY_GATEWAY;
    },
    gatewayMerchantId(): string {
      return Config.GPAY_GATEWAY_MERCHANT_ID;
    }
  }
}

