import Env from 'environment/env';
import Main from 'facades/main';
import JSONWrapper from 'helpers/json-wrapper';
import Session from 'helpers/session';
import Logger from 'helpers/logger';
import { ApplePayEvent } from 'events/payment-event';
import { CurrentState } from "state/state";
import { LoginStatus } from "concerns/login-status";
import { SubscribeAPIResponse } from "concerns/subscribe-api-response";
import { SubscribeAPIApplePayPaymentSessionResponse } from "concerns/subscribe-api-applepay-payment-session-response";
import { SubscribeAPIApplePayPaymentSessionRequest } from "concerns/subscribe-api-applepay-payment-session-request";
import PaymentRequestBuilder from '../request-builders/payment-request-builder';

export default class ApplePaySessionProvider {
  private session: ApplePaySession;

  public constructor(
    private plan_title: string,
    private variation_id: number,
    private lineTotal: ApplePayJS.ApplePayLineItem,
    private notify: Function
  ) { }

  public async initialize(): Promise<void> {
    this.session = new ApplePaySession(6, await this.paymentRequest());

    this.session.onvalidatemerchant = (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
      return this.onValidateMerchant(event, this);
    };

    this.session.onpaymentmethodselected = (event: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => {
      return this.onPaymentMethodSelected(event, this);
    }

    this.session.onshippingcontactselected = (event: ApplePayJS.ApplePayShippingContactSelectedEvent) => {
      return this.onShippingContactSelected(event, this);
    }

    this.session.onshippingmethodselected = (event: ApplePayJS.ApplePayShippingMethodSelectedEvent) => {
      return this.onShippingMethodSelected(event, this);
    }

    this.session.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
      return this.onPaymentAuthorized(event, this);
    }

    return this.session.begin();
  }

  public completePayment(success: boolean, errors: Array<ApplePayJS.ApplePayError> = []) {
    const status = success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE;
    const result: ApplePayJS.ApplePayPaymentAuthorizationResult  = {
      status,
      errors
    };

    Logger.debug('[ApplePay] completePayment', result);

    this.session.completePayment(result);
  }

  private async paymentRequest(): Promise<ApplePayJS.ApplePayPaymentRequest> {
    return new PaymentRequestBuilder().request(this.lineTotal);
  }

  private onValidateMerchant(event: ApplePayJS.ApplePayValidateMerchantEvent, provider: ApplePaySessionProvider) {
    Main.getLoginStatus((loginstatus: LoginStatus) => {
      const request = {
        user_id: CurrentState.user.getId(),
        access_token: loginstatus.accessToken,
        validation_url: 'apple-pay-gateway.apple.com'
      } as SubscribeAPIApplePayPaymentSessionRequest;

      Main.getSubscribeApi().getApplePayPaymentSession(request)
        .then((response: SubscribeAPIResponse<SubscribeAPIApplePayPaymentSessionResponse>) => {
          Logger.debug('[ApplePay] onValidateMerchant', response);

          if (response.code === 'success' || provider.canSkipMerchantValidation()) {
            Logger.debug('[ApplePay] Calling completeMerchantValidation');
            provider.session.completeMerchantValidation(response.data);
            return;
          }

          Logger.error('[ApplePay] onValidateMerchant error', response);
        })
        .catch(error => console.error(error));
      }, true);
  }

  private onPaymentMethodSelected(event: ApplePayJS.ApplePayPaymentMethodSelectedEvent, provider: ApplePaySessionProvider) {
    Logger.debug('[ApplePay] onPaymentMethodSelected', event);

    const update: ApplePayJS.ApplePayPaymentMethodUpdate = {
      newTotal: provider.lineTotal,
      newLineItems: [ provider.lineTotal ],
    };

    provider.session.completeShippingContactSelection(update);
  }

  private onShippingContactSelected(event: ApplePayJS.ApplePayShippingContactSelectedEvent, provider: ApplePaySessionProvider) {
    Logger.debug('[ApplePay] onShippingContactSelected', event);

    const update: ApplePayJS.ApplePayShippingContactUpdate = {
      errors: [],
      newTotal: provider.lineTotal,
      newLineItems: [ provider.lineTotal ],
    };

    provider.session.completePaymentMethodSelection(update);
  }

  private onShippingMethodSelected(event: ApplePayJS.ApplePayShippingMethodSelectedEvent, provider: ApplePaySessionProvider) {
    Logger.debug('[ApplePay] onShippingMethodSelected', event);

    const update: ApplePayJS.ApplePayShippingMethodUpdate = {
      newTotal: provider.lineTotal,
      newLineItems: [ provider.lineTotal ],
    };

    provider.session.completeShippingMethodSelection(update);
  }

  private onPaymentAuthorized(event: ApplePayJS.ApplePayPaymentAuthorizedEvent, provider: ApplePaySessionProvider) {
    Logger.debug('[ApplePay] onPaymentAuthorized', event.payment);

    const paymentData = {
      user_phone: provider.formValue('telephone'),
      user_identity: provider.formValue('cpf'),
      plan_title: provider.plan_title,
      variation_id: provider.variation_id,
      installments: 1
    };

    const notification: ApplePayEvent = Object.assign({}, event.payment, paymentData);
    provider.notify(notification);
  }

  private formValue(name: string): string {
    const element = document.getElementsByName(name);
    if (element.length === 0) {
      return '';
    }

    return (element[0] as HTMLInputElement).value;
  }

  private canSkipMerchantValidation(): boolean {
    return Env.get() === 'development';
  }
}
