export default class PaymentRequestBuilder {
  public request(lineTotal: ApplePayJS.ApplePayLineItem): ApplePayJS.ApplePayPaymentRequest {
    return {
      currencyCode: 'BRL',
      countryCode: 'BR',
      merchantCapabilities: [
        'supports3DS',
        'supportsCredit'
      ],
      supportedNetworks: [
        'amex',
        'masterCard',
        'maestro',
        'visa',
        'mada',
        'jcb',
        'elo',
        'vPay'
      ],
      total: lineTotal
    }
  }
}
