import LoginStatusStatus from "./login-status-status";

export default class SubscriberStatus {
  public static FORMER_SUBSCRIBER = "former_subscriber";
  public static SUBSCRIBER = "subscriber";

  public static parseUserInfo(userdata: Array<any>): string {
    const userinfo = userdata[0] || {};
    const subscriberStatus = userinfo.CHECK_ASS || '';

    if (subscriberStatus === "ASSINANTE") {
      return this.SUBSCRIBER;
    }

    if (subscriberStatus === "EX-ASSINANTE") {
      return this.FORMER_SUBSCRIBER;
    }

    return LoginStatusStatus.CONNECTED;
  }
}
