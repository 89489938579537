import Env from 'environment/env';

export class PaymentOptionsProvider {
  public getPaymentOptions(): google.payments.api.PaymentOptions {
    return {
      environment: Env.gpay.environment() as google.payments.api.Environment,
      paymentDataCallbacks: {
        onPaymentAuthorized: (paymentData) => {
          return new Promise<google.payments.api.PaymentAuthorizationResult>((resolve, reject) => {
            resolve({ transactionState: 'SUCCESS' });
          });
        }
      }
    };
  }
}
