import Observer from "observers/observer";
import PlanSelectEvent from "events/plan-select-event";
import Analytics from "helpers/analytics";

export default class PaymentScreen implements Observer<PlanSelectEvent> {
  constructor(private analytics: Analytics) {}

  public async handle(event: PlanSelectEvent): Promise<void> {
    if (this.isDigitalCampaign(event)) {
      this.handleDigitalCampaign(event);
      return;
    }

    this.handleNonDigitalCampign(event);
  };

  private isDigitalCampaign(event: PlanSelectEvent) {
    return event.campaign.is_digital;
  }

  public showPlanSelectScreen() {
    const plans = document.getElementById('plans');
    plans.style.display = 'block';

    this.formElement().classList.remove('show-form');
    this.buttonElement().style.display = "none";

    this.activateScreenSelector('plans', true);
    this.activateScreenSelector('payment', false);

    // Analytics
    this.analytics.send({event: 'paywall_screen', screen: 'plans'});
  }

  private hidePlanSelectScreen() {
    const plans = document.getElementById('plans');
    plans.style.display = 'none';

    this.activateScreenSelector('plans', false);
    this.activateScreenSelector('payment', true);
  }

  private handleNonDigitalCampign(event: PlanSelectEvent) {
    this.linkElement().setAttribute("href", event.campaign.add_to_cart_url);
    this.buttonElement().style.display = "flex";
    this.formElement().classList.remove('show-form');

    this.hidePlanSelectScreen();

    // Analytics
    this.analytics.send({event: 'paywall_screen', screen: 'payment', paywall_selected_plan: event.campaign.name, paywall_selected_plan_type: 'non-digital'});
  }

  private handleDigitalCampaign(event: PlanSelectEvent) {
    this.linkElement().setAttribute("href", "");
    this.buttonElement().style.display = "none";
    this.formElement().classList.add('show-form');

    this.hidePlanSelectScreen();

    // Analytics
    this.analytics.send({event: 'paywall_screen', screen: 'payment', paywall_selected_plan: event.campaign.name, paywall_selected_plan_type: 'digital'});
  }

  private formElement() {
    return document.getElementById("checkout-form");
  }

  private paymentSection(): HTMLElement {
    return document.getElementById("payment-section");
  }

  private linkElement(): HTMLLinkElement {
    return document.getElementById("assine-link") as HTMLLinkElement;
  }

  private buttonElement(): HTMLElement {
    return document.getElementById("assine-button");
  }

  private activateScreenSelector(type: string, show: boolean): void {
    const element = document.getElementById(`checkout-screen-selector-${type}`);
    element.classList.toggle('active-line', show);
  }
}
