import BaseRequestBuilder from './base-request-builder';
import Env from 'environment/env';
/**
 * Build a payment data request based on sub fields injected on construtor
 */
export class PaymentDataRequestBuilder extends BaseRequestBuilder {
  /**
   * @returns {google.payments.api.PaymentDataRequest} that represents the request builded based on injected providers
   */
  public getRequest(transactionInfo: google.payments.api.TransactionInfo): google.payments.api.PaymentDataRequest {

    return {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: this.getAllowedPaymentMethods(),
      transactionInfo: transactionInfo,
      merchantInfo: {
        merchantId: Env.gpay.merchantId(),
        merchantName: Env.gpay.merchantName(),
      },
      callbackIntents: ['PAYMENT_AUTHORIZATION'],
      shippingAddressRequired: false,
    }
  }
}
