import Observer from 'observers/observer';
import Format from 'helpers/format';
import InstallmentSelectEvent from 'events/installment-select-event';

export default class FillResumeInstallment implements Observer<InstallmentSelectEvent> {
  public async handle(event: InstallmentSelectEvent): Promise<void> {
    const installment = document.getElementById("resume-installments");
    const totalValue = document.getElementById("resume-total-value") as HTMLInputElement;

    const total = Number(totalValue.value) / event.installment;

    installment.innerHTML = `${event.installment}x R$ ${Format.price(total)} s/ juros`;
  };
}
