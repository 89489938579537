import { SubscribeApiPayment } from "concerns/subscribe-api-payment";
import { ApplePayEvent } from "events/payment-event";

export default class AppleToSubscribeApiPaymentMap {
  public map(source: ApplePayEvent): Partial<SubscribeApiPayment> {
    return {
      plan_title: source.plan_title,
      variation_id: source.variation_id,
      installments: 1,
      type: "apple-pay",
      wl_token: source.token.paymentData.header.ephemeralPublicKey,
      wl_walletkey: source.token.paymentData.data,
      user_identity: source.user_identity,
      user_phone: source.user_phone
    };
  }
}
