import Observer from "observers/observer";
import PlanSelectEvent from "events/plan-select-event";
import LoadedEWalletEvent from "events/loaded-ewallet-event";
import { PaymentEvent, GPayEvent, ApplePayEvent } from "events/payment-event";

export default class EWalletAvailability implements Observer<PlanSelectEvent>, Observer<LoadedEWalletEvent> {
  private installments: Array<number> = [];

  public async handle(event: PlanSelectEvent | LoadedEWalletEvent | PaymentEvent): Promise<void> {
    if ((event as PlanSelectEvent).campaign !== undefined) {
      this.installments = (event as PlanSelectEvent).campaign.installments;
    }

    if ((event as PaymentEvent).variation_id !== undefined) {
      return;
    }

    const wallets = document.getElementById('ewallets-wrapper');
    const ccHeader = document.getElementById('credit-card-form-header');

    const showWallets = this.canBePaid();

    wallets.classList.toggle("hidden", ! showWallets);
    ccHeader.classList.toggle("hidden", showWallets);
  };

  private canBePaid() {
    const validInstallments = this.installments.indexOf(1) >= 0;
    const isThereButtons = this.anyLoadedButton();

    return validInstallments && isThereButtons;
  }

  private anyLoadedButton() {
    const wallets = document.getElementById('ewallets-buttons');
    return wallets.innerHTML !== '';
  }
}
