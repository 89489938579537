export default class JSONWrapper {
  public static parse(json: string): any {
    try {
      return JSON.parse(json);
    } catch (exception) {
      return this.jsonError(`"${json}" ${exception}`);
    }
  }

  public static stringify(object: any): string {
    return JSON.stringify(object);
  }

  public static jsonError(msg: string): object {
    return {
      error: {
        message: `Invalid JSON: ${msg}`,
      },
    };
  }
}
