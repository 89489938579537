import PaymentButton from "payment/interface/payment-button";
import Observer from "observers/observer";
import PlanSelectEvent from "events/plan-select-event";
import InstallmentSelectEvent from "events/installment-select-event";
import Button from "components/button";
import { PaymentEvent } from "events/payment-event";
import JCButton from "components/jc-button";
import { SubscribeApiPayment } from "concerns/subscribe-api-payment";

export default class FormPay extends PaymentButton implements Observer<PlanSelectEvent>, Observer<InstallmentSelectEvent>  {
  private button: Button<PaymentEvent>;
  private payment: Partial<SubscribeApiPayment>;

  public constructor() {
    super();

    this.payment = {};
    this.button = new JCButton<PaymentEvent>({
      class: "btn-submit",
      text: "Confirmar pedido",
      type: "submit",
      id: "form-button"
    });
  }

  public async allowed(): Promise<boolean> {
    return true;
  }

  public async createButton(): Promise<void> {
    return;
  }

  public async build(): Promise<HTMLElement> {
    const formButton = await this.button.build();

    formButton.addEventListener("click", () => {
      this.onClick();
    });

    return formButton;
  }

  private onClick() {
    if (! this.validateForm()) {
      return;
    }

    this.completePayment();
    this.notify(this.payment);
  }

  private validateForm() {
    const formElement = document.getElementById("checkout-form") as HTMLFormElement;
    return formElement !== undefined && formElement.reportValidity();
  }

  private completePayment() {
    this.payment = Object.assign(this.payment, {
      cc_number: this.formValue("cc_number"),
      cc_holder: this.formValue("cc_holder"),
      cc_expiration_date: this.formValue("month") + "/" + this.formValue("year"),
      cc_security_code: this.formValue("cc_security_code"),
      installments: Number(this.formValue("installments")),
      user_phone: this.formValue("telephone"),
      user_identity: this.formValue("cpf"),
      type: "credit-card",
      plan_title: this.titleValue(),
    });
  }

  private titleValue(): string {
    return document.querySelector("#resume-title > span").innerHTML;
  }

  private formValue(name: string): string {
    const element = document.getElementsByName(name);
    if (element.length === 0) {
      return '';
    }

    return (element[0] as HTMLInputElement).value;
  }

  public style(): string {
    return this.button.style();
  }

  public async handle(event: PlanSelectEvent | InstallmentSelectEvent): Promise<void> {
    if ((event as PlanSelectEvent).button !== undefined) {
      return this.onPlanSelect(event as PlanSelectEvent);
    }

    return this.onInstallmentSelect(event as InstallmentSelectEvent);
  }

  private async onPlanSelect(event: PlanSelectEvent): Promise<void> {
    this.payment.variation_id = event.campaign.id;
  }

  private async onInstallmentSelect(event: InstallmentSelectEvent): Promise<void> {}
}
