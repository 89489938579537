import * as Cookies from "js-cookie";
import { parse } from "tldts";

import Logger from "helpers/logger";

/**
 * Check we have localstorage.
 */
const localStorageIsAvailable = ((): boolean => {
  if (typeof (window as Window).localStorage === 'undefined') {
    return false;
  }

  try {
    (window as Window).localStorage.setItem('feature_test', 'yes');
    if ((window as Window).localStorage.getItem('feature_test') === 'yes') {
      (window as Window).localStorage.removeItem('feature_test');
      return true;
    }
  } catch(e) {}

  return false;
})();

export default class Session {
  /**
   * If changing, check for this token on another projects.
   */
  public static readonly accessTokenKey = "_sjcc_access_token";
  public static readonly refreshTokenKey = "_sjcc_refresh_token";
  public static readonly callbackStateKey = "_sjcc_kc_state";

  public static hasLocalStorage = localStorageIsAvailable;

  public static readonly cookieParams: Cookies.CookieAttributes = {
    domain: parse((window as Window).location.href).domain,
    expires: 365,
    sameSite: Session.shouldBeSecure() ? "none" : "lax",
    secure: Session.shouldBeSecure(),
  };

  /**
   * Get access token.
   *
   * @return {string}
   */
  public static getAccessToken(): string {
    let token = '';

    if (this.hasLocalStorage) {
      token = localStorage.getItem(this.accessTokenKey);
      token = (token === 'undefined') ? '' : token;
    }

    if (! token) {
      token = Cookies.get(this.accessTokenKey);
    }

    return token ? token : '';
  }

  /**
   * Get refresh token.
   *
   * @return {string}
   */
  public static getRefreshToken(): string {
    let token = '';

    if (this.hasLocalStorage) {
      token = localStorage.getItem(this.refreshTokenKey);
      token = (token === 'undefined') ? '' : token;
    }

    if (! token) {
      token = Cookies.get(this.refreshTokenKey);
    }

    return token ? token : '';
  }

  /**
   * Set access token.
   *
   * @param {string} token
   */
  public static setAccessToken(token: string) {
    if (this.hasLocalStorage) {
      localStorage.setItem(this.accessTokenKey, token);
    }

    Cookies.set(this.accessTokenKey, token, this.cookieParams);
  }

  /**
   * Set refresh token.
   *
   * @param {string} token
   */
  public static setRefreshToken(token: string) {
    if (this.hasLocalStorage) {
      localStorage.setItem(this.refreshTokenKey, token);
    }

    Cookies.set(this.refreshTokenKey, token, this.cookieParams);
  }

  /**
   * Get access and refresh tokens.
   */
  public static getTokens(): { access_token: string, refresh_token: string } {
    return {
      access_token: this.getAccessToken(),
      refresh_token: this.getRefreshToken(),
    };
  }

  /**
   * Set access and refresh tokens
   * @param {{string}} tokens
   */
  public static setTokens(tokens: { access_token: string, refresh_token: string }) {
    this.setAccessToken(tokens.access_token);
    this.setRefreshToken(tokens.refresh_token);
  }

  /**
   * Clear session.
   */
  public static forgetTokens() {
    Logger.debug("Forgetting Tokens");

    if (this.hasLocalStorage) {
      localStorage.removeItem(this.accessTokenKey);
      localStorage.removeItem(this.refreshTokenKey);
    }

    Cookies.remove(this.accessTokenKey, this.cookieParams);
    Cookies.remove(this.refreshTokenKey, this.cookieParams);
  }

  /**
   * Check we should set secure property of cookie.
   * For Dev/Staging from SJCC IT
   *
   * @return {boolean}
   */
  private static shouldBeSecure(): boolean {
    const { host, protocol } = window.location;

    const isDevelopment = host === "front.dev.sjcc.com.br" || host === "localhost";

    if (protocol === "http:" && isDevelopment) {
      return false;
    }

    return true;
  }

  /**
   * Set state.
   * @param {string} state
   */
  public static setState(state: string) {
    Cookies.set(this.callbackStateKey, state, this.cookieParams);
  }

  /**
   * Clear state.
   */
  public static forgetState() {
    Logger.debug("Forgetting state");
    Cookies.remove(this.callbackStateKey, this.cookieParams);
  }

  /**
   * Check state cookie.
   * @param {string} state
   */
  public static validateState(state: string): boolean {
    const challenge = Cookies.get(this.callbackStateKey);

    return challenge && challenge === state;
  }

  /**
   * Generate UUID for session.
   * From keycloak.js
   */
  public static generateUUID() {
    const uuid = [];
    const hexDigits = '0123456789abcdef';

    for (let i = 0; i < 36; i++) {
      uuid[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    uuid[14] = '4';
    uuid[19] = hexDigits.substr((uuid[19] && 3) || 8, 1);
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';

    return uuid.join('');
  }
}
